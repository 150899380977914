import React from 'react';
import { useDispatch } from 'react-redux';
import { getInitialFilter, getOptionValidationRequest, getSelectedOptionNames, resetSpeedFilter, setSecondaryFilter, setSelectedOption } from '../../services';
import { Filter, OptionValidationRequest, SecondaryFilter } from '../../models';
import { INITIAL_POWER, TRANSLATIONS } from '../../constants';
import { addNewIds, clearHiddenIds, toggleSideSheet, setAvailableValues, setFilter, setPowerText, toggleVerticalView, setMotors, setOptionData, setInitialSecondaryFilter } from '../../store';
import Button, { ButtonType } from '../common/Button';
import ButtonGroup, { GroupButton } from '../common/ButtonGroup';
import Flex, { FlexDirection, FlexJustification } from '../common/Flex';
import Icon from '../common/Icon';
import { IconType } from '../../enums';
import { SectionColor } from '../common/Section';
import { TagProps } from '../common/Tag';
import TagBar from '../common/TagBar';
import { useAppSelector, useTranslate } from '../../hooks/common';

interface SelectorTagBarProps {
    backgroundColor: SectionColor;
    onOptionValidation: (request: OptionValidationRequest) => void;
    onSecondaryFilter: (filter: Filter) => void;
}

const SelectorTagBar = ({ backgroundColor, onOptionValidation, onSecondaryFilter }: SelectorTagBarProps) => {
    const dispatch = useDispatch();
    const filter = useAppSelector(state => state.selector.filter);
    const hiddenIds = useAppSelector(state => state.selector.hiddenIds);
    const initialAvailableValues = useAppSelector(state => state.selector.initialAvailableValues);
    const initialSecondaryFilter = useAppSelector(state => state.selector.initialSecondaryFilter);
    const loading = useAppSelector(state => state.selector.loading);
    const motors = useAppSelector(state => state.selector.motors);
    const optionData = useAppSelector(state => state.selector.optionData);
    const selectedOptionNames = useAppSelector(state => getSelectedOptionNames(state.selector.optionData));
    const verticalView = useAppSelector(state => state.selector.verticalView);
    const translate = useTranslate();

    const handleFilterReset = () => {
        dispatch(addNewIds(hiddenIds));
        dispatch(clearHiddenIds());
    };

    const handlePowerReset = () => {
        dispatch(setFilter(getInitialFilter()));
        dispatch(setPowerText(INITIAL_POWER.toString()));
        dispatch(setMotors([]));
        dispatch(setOptionData(undefined));
        dispatch(clearHiddenIds());
        dispatch(setInitialSecondaryFilter(undefined));
        initialAvailableValues && dispatch(setAvailableValues(initialAvailableValues));
    };

    const handleSpeedReset = () => {
        dispatch(setFilter(resetSpeedFilter(filter)));
        dispatch(setMotors([]));
        dispatch(setOptionData(undefined));
        dispatch(clearHiddenIds());
        dispatch(setInitialSecondaryFilter(undefined));
    };

    const handleSecondaryReset = (secondaryFilter: Partial<SecondaryFilter>) => {
        const newFilter = setSecondaryFilter(filter, secondaryFilter);

        dispatch(setFilter(newFilter));
        onSecondaryFilter(newFilter);
    };

    const handleOptionReset = (name: string) => {
        if (optionData) {
            dispatch(setOptionData(setSelectedOption(optionData, name)));
            onOptionValidation(getOptionValidationRequest(motors, name, optionData));
        }
    };

    const getTagProps = (text: string, onClick: () => void): TagProps => ({ text, onClick, disabled: loading, close: true });

    type ObjectKey = keyof typeof TRANSLATIONS.option;

    const tags: TagProps[] = [
        ...hiddenIds.length
            ? [getTagProps(translate(TRANSLATIONS.main.filterActive), handleFilterReset)]
            : [],
        getTagProps(`${filter.primaryFilter.power} kW`, handlePowerReset),
        ...filter.primaryFilter.speed
            ? [getTagProps(`${filter.primaryFilter.speed} ${translate(TRANSLATIONS.main.rpm)}`, handleSpeedReset)]
            : [],
        ...filter.secondaryFilter?.winding !== initialSecondaryFilter?.winding
            ? [getTagProps(filter.secondaryFilter?.winding.value ?? '', () => handleSecondaryReset({ winding: initialSecondaryFilter?.winding }))]
            : [],
        ...filter.secondaryFilter?.material !== initialSecondaryFilter?.material
            ? [getTagProps(filter.secondaryFilter?.material.value ?? '', () => handleSecondaryReset({ material: initialSecondaryFilter?.material }))]
            : [],
        ...filter.secondaryFilter?.typeOfConstruction !== initialSecondaryFilter?.typeOfConstruction
            ? [getTagProps(filter.secondaryFilter?.typeOfConstruction.value ?? '', () => handleSecondaryReset({ typeOfConstruction: initialSecondaryFilter?.typeOfConstruction }))]
            : [],
        ...filter.secondaryFilter?.motorProtection !== initialSecondaryFilter?.motorProtection
            ? [getTagProps(filter.secondaryFilter?.motorProtection.value ?? '', () => handleSecondaryReset({ motorProtection: initialSecondaryFilter?.motorProtection }))]
            : [],
        ...filter.secondaryFilter?.terminalBox !== initialSecondaryFilter?.terminalBox
            ? [getTagProps(filter.secondaryFilter?.terminalBox.value ?? '', () => handleSecondaryReset({ terminalBox: initialSecondaryFilter?.terminalBox }))]
            : [],
        ...filter.secondaryFilter?.spFilter !== initialSecondaryFilter?.spFilter
            ? [getTagProps(filter.secondaryFilter?.spFilter.value ?? '', () => handleSecondaryReset({ spFilter: initialSecondaryFilter?.spFilter }))]
            : [],
        ...selectedOptionNames.map(x => {
            const key = x as ObjectKey;
            return getTagProps(`${x} ${translate(TRANSLATIONS.option[key])}`, () => handleOptionReset(x));
        }
        )
    ];

    const viewButtons: GroupButton[] = [
        { children: <Icon type={IconType.VerticalView} />, disabled: verticalView, onClick: () => dispatch(toggleVerticalView()) },
        { children: <Icon type={IconType.HorizontalView} />, disabled: !verticalView, onClick: () => dispatch(toggleVerticalView()) }
    ];

    const renderTagBarActions = () => (
        <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd} gap={10}>
            <ButtonGroup type={ButtonType.Secondary} buttons={viewButtons} />
            <Button type={ButtonType.Secondary} onClick={() => dispatch(toggleSideSheet())}>
                <Icon type={IconType.Filter} />
            </Button>
        </Flex>
    );

    return (
        <div className='selector-tag-bar'>
            <TagBar backgroundColor={backgroundColor} tags={tags} actions={renderTagBarActions()} />
        </div>
    );
};

export default SelectorTagBar;
