import { getSelectedOptions } from '.';
import { AvailableValues, Filter, FilterValue, Motor, OptionData, SecondaryFilter } from '../models';
import { INITIAL_POWER } from '../constants';

export const addPinnedMotors = (motors: Motor[], pinnedMotors: Motor[]) => pinnedMotors.concat(motors.filter(x => !pinnedMotors.map(y => y.id).includes(x.id)));

export const filterMotors = (motors: Motor[], hiddenIds: string[], optionData: OptionData | undefined) =>
    motors.filter(x => !hiddenIds.includes(x.id) && getSelectedOptions(optionData).every(y => y.validMlfbs.includes(x.mlfb)));

export const getInitialFilter = (): Filter => ({ primaryFilter: { power: INITIAL_POWER, speed: 0 }, secondaryFilter: undefined });

export const getSelectedPower = (availableValues: AvailableValues, powerText: string) =>
    availableValues.power.find(x => x >= Number(powerText)) ?? availableValues.power[availableValues.power.length - 1];

export const needAnimation = (motorIds: string[], hiddenMotorIds: string[], pinnedMotorIds: string[], motorId: string) => {
    const firstNotPinnedId = motorIds.find(x => !pinnedMotorIds.includes(x) && !hiddenMotorIds.includes(x));
    const lastPinnedIdWhichWouldBeFirst = motorIds.find(x => pinnedMotorIds[pinnedMotorIds.length - 1] === x
        && motorIds.find(y => !pinnedMotorIds.filter(z => z !== x).includes(y) && !hiddenMotorIds.includes(y)) === x);

    return ![firstNotPinnedId, lastPinnedIdWhichWouldBeFirst].includes(motorId);
};

export const resetSpeedFilter = (filter: Filter): Filter => ({ primaryFilter: { ...filter.primaryFilter, speed: 0 }, secondaryFilter: undefined });

export const setPowerFilter = (filter: Filter, power: number): Filter => ({ ...filter, primaryFilter: { ...filter.primaryFilter, power } });

export const setSecondaryFilter = (filter: Filter, secondaryFilter: Partial<SecondaryFilter>): Filter =>
    ({ ...filter, secondaryFilter: filter.secondaryFilter ? { ...filter.secondaryFilter, ...secondaryFilter } : undefined });

export const setSpeedFilter = (filter: Filter, speed: number): Filter => ({ ...filter, primaryFilter: { ...filter.primaryFilter, speed } });

export const getFilterValue = (value: FilterValue | undefined) => value || { value: '', isAvailable: true } as FilterValue;

export const getIsDisabled = (value: FilterValue | undefined, filter: Filter) => !getIsAvailable(value) && !getIsFilterValid(filter);

export const getIsAvailable = (value: FilterValue | undefined) => !(value?.isAvailable === false);

export const getIsFilterValid = (filter: Filter) => {
    return filter && filter.secondaryFilter &&
           filter.secondaryFilter.material?.isAvailable &&
           filter.secondaryFilter.motorProtection?.isAvailable &&
           filter.secondaryFilter.productGroup?.isAvailable &&
           filter.secondaryFilter.spFilter?.isAvailable &&
           filter.secondaryFilter.terminalBox?.isAvailable &&
           filter.secondaryFilter.typeOfConstruction?.isAvailable &&
           filter.secondaryFilter.winding?.isAvailable;
};
