import { CommercialDataRequest, OptionValidationRequest } from '../models';

export const getCommercialDataRequest = (optionRequest: OptionValidationRequest): CommercialDataRequest => {
  const alreadySelectedOptions = !isEmpty(optionRequest.options[0]) || optionRequest.options.length !== 1
    ? optionRequest.options.sort()
    : [];

  const mlfb = optionRequest.mlfbs.map(x => x.mlfb) ?? [];

  return { mlfbs: mlfb, options: alreadySelectedOptions };
};

const isEmpty = (str: string) => !str || !str.trim();
