import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INITIAL_POWER } from '../constants';
import { AvailableValues, CommercialDataResponse, Filter, Motor, OptionData, SecondaryFilter, SpcData } from '../models';

export interface SelectorState {
    availableValues: AvailableValues;
    basicFilterOpen: boolean;
    commercialData?: CommercialDataResponse[];
    dtkData?: {
        keepAliveUrl: string;
        resultUrl: string;
    };
    spcData: SpcData;
    filter: Filter;
    hiddenIds: string[];
    initialAvailableValues?: AvailableValues;
    initialSecondaryFilter?: SecondaryFilter;
    loading: boolean;
    loadingOptions: boolean;
    loadingDeliveryTimes: boolean;
    moreOpen: boolean;
    motors: Motor[];
    newIds: string[];
    optionData?: OptionData;
    pinnedMotors: Motor[];
    powerText: string;
    sideSheetOpen: boolean;
    verticalView: boolean;
}

const initialState: SelectorState = {
    motors: [],
    basicFilterOpen: true,
    commercialData: [],
    filter: { primaryFilter: { power: INITIAL_POWER } },
    hiddenIds: [],
    loading: false,
    loadingDeliveryTimes: false,
    loadingOptions: false,
    moreOpen: false,
    newIds: [],
    pinnedMotors: [],
    powerText: INITIAL_POWER.toString(),
    sideSheetOpen: false,
    verticalView: true,
    spcData: {
        language: 'EN',
        region: 'DE'
    },
    availableValues: {
        material: [],
        motorProtection: [],
        power: [],
        speed: [],
        spFilter: [],
        terminalBox: [],
        typeOfConstruction: [],
        winding: [],
        productGroup: []
    }
};

export const selectorSlice = createSlice({
    name: 'selector',
    initialState,
    reducers: {
        addHiddenId: (state: SelectorState, action: PayloadAction<string>) => {
            state.hiddenIds = [...state.hiddenIds, action.payload];
        },
        addNewId: (state: SelectorState, action: PayloadAction<string>) => {
            state.newIds = [...state.newIds, action.payload];
        },
        addNewIds: (state: SelectorState, action: PayloadAction<string[]>) => {
            state.newIds = [...state.newIds, ...action.payload];
        },
        addPinned: (state: SelectorState, action: PayloadAction<Motor>) => {
            state.pinnedMotors = [...state.pinnedMotors, action.payload];
        },
        clearHiddenIds: (state: SelectorState) => {
            state.hiddenIds = [];
        },
        clearNewIds: (state: SelectorState) => {
            state.newIds = [];
        },
        removeNewId: (state: SelectorState, action: PayloadAction<string>) => {
            state.newIds = state.newIds.filter(x => x !== action.payload);
        },
        removePinned: (state: SelectorState, action: PayloadAction<string>) => {
            state.pinnedMotors = state.pinnedMotors.filter(x => x.id !== action.payload);
        },
        setAvailableValues: (state: SelectorState, action: PayloadAction<AvailableValues>) => {
            state.availableValues = action.payload;
        },
        setCommercialData: (state: SelectorState, action: PayloadAction<CommercialDataResponse[]>) => {
            state.commercialData = action.payload;
        },
        setDtkData: (state: SelectorState, action: PayloadAction<{ keepAliveUrl: string; resultUrl: string; }>) => {
            state.dtkData = action.payload;
        },
        setSpcData: (state: SelectorState, action: PayloadAction<SpcData>) => {
            state.spcData = action.payload;
        },
        setFilter: (state: SelectorState, action: PayloadAction<Filter>) => {
            state.filter = action.payload;
        },
        setInitialAvailableValues: (state: SelectorState, action: PayloadAction<AvailableValues>) => {
            state.availableValues = action.payload;
        },
        setInitialSecondaryFilter: (state: SelectorState, action: PayloadAction<SecondaryFilter | undefined>) => {
            state.initialSecondaryFilter = action.payload;
        },
        setMotors: (state: SelectorState, action: PayloadAction<Motor[]>) => {
            state.motors = action.payload;
        },
        setOptionData: (state: SelectorState, action: PayloadAction<OptionData | undefined>) => {
            state.optionData = action.payload;
        },
        setPower: (state: SelectorState, action: PayloadAction<number>) => {
            state.filter = { ...state.filter, primaryFilter: { ...state.filter.primaryFilter, power: action.payload } };
        },
        setPowerText: (state: SelectorState, action: PayloadAction<string>) => {
            state.powerText = action.payload;
        },
        toggleBasicFilter: (state: SelectorState) => {
            state.basicFilterOpen = !state.basicFilterOpen;
        },
        toggleLoading: (state: SelectorState) => {
            state.loading = !state.loading;
        },
        toggleLoadingOptions: (state: SelectorState) => {
            state.loadingOptions = !state.loadingOptions;
        },
        toggleLoadingDeliveryTimes: (state: SelectorState) => {
            state.loadingDeliveryTimes = !state.loadingDeliveryTimes;
        },
        toggleMore: (state: SelectorState) => {
            state.moreOpen = !state.moreOpen;
        },
        toggleSideSheet: (state: SelectorState) => {
            state.sideSheetOpen = !state.sideSheetOpen;
        },
        toggleVerticalView: (state: SelectorState) => {
            state.verticalView = !state.verticalView;
        }
    }
});

export const {
    addHiddenId,
    addNewId,
    addNewIds,
    addPinned,
    clearHiddenIds,
    clearNewIds,
    removeNewId,
    removePinned,
    setAvailableValues,
    setCommercialData,
    setDtkData,
    setSpcData,
    setFilter,
    setInitialAvailableValues,
    setInitialSecondaryFilter,
    setMotors,
    setOptionData,
    setPower,
    setPowerText,
    toggleBasicFilter,
    toggleLoading,
    toggleLoadingDeliveryTimes,
    toggleLoadingOptions,
    toggleMore,
    toggleSideSheet,
    toggleVerticalView
} = selectorSlice.actions;
export const selectorReducer = selectorSlice.reducer;
