import React, { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import Footer from './Footer';
import Header from './Header';
import Snackbars from '../common/Snackbars';
import { useAppSelector } from '../../hooks/common';
import { removeSnackbar } from '../../store';

interface LayoutProps {
    children?: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
    const dispatch = useDispatch();
    const snackbars = useAppSelector(state => state.snackbars.snackbars);

    return (
        <div className='page'>
            <Header />
            <main id='main' tabIndex={-1}>
                {children}
            </main>
            <Snackbars snackbars={snackbars} removeSnackbar={x => dispatch(removeSnackbar(x))} />
            <Footer />
        </div>
    );
};

export default Layout;
