import { ApiBase } from '.';
import { AvailableValues, CommercialDataRequest, Document, DocumentRequest, Filter, OptionData, OptionValidationRequest, PrimaryFilter, PrimaryFilterResponse, SecondaryFilterResponse, ServiceDataResult } from '../models';
import { CommercialDataResponse } from '../models/CommercialDataResponse';

export class Api extends ApiBase {
    static getInitialValues = () => Api.get('api/motor/initialValues') as Promise<ServiceDataResult<AvailableValues>>;

    static getValues = (power: number) => Api.get(`api/motor/values/${power}`) as Promise<ServiceDataResult<AvailableValues>>;

    static primaryFilter = (filter: PrimaryFilter) => Api.post('api/motor/primaryFilter', filter) as Promise<ServiceDataResult<PrimaryFilterResponse>>;

    static secondaryFilter = (filter: Filter) => Api.post('api/motor/secondaryFilter', filter) as Promise<ServiceDataResult<SecondaryFilterResponse>>;

    static optionValidation = (request: OptionValidationRequest) => Api.post('api/motor/optionValidation', request) as Promise<ServiceDataResult<OptionData>>;

    static getCommercialData = (request: CommercialDataRequest) => Api.post('api/motor/commercialData', request) as Promise<ServiceDataResult<CommercialDataResponse[]>>;

    static getDocument = (request: DocumentRequest) => Api.post('api/motor/document', request) as Promise<ServiceDataResult<Document>>;

    static isProduction = () => Api.get('api/environment/production') as Promise<ServiceDataResult<boolean>>;
}
