export enum Language {
    En,
    De,
    Es,
    Fr,
    It,
    Pt,
    Ru,
    Cn,
    Tr,
    Nl,
    Pl,
    Ch
}
