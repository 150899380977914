import React, { ChangeEvent, CSSProperties, FocusEvent, KeyboardEvent, useRef } from 'react';

interface InputProps {
    label: string;
    name: string;
    value: string;
    onChange: (value: string, name: string) => void;
    dark?: boolean;
    disabled?: boolean;
    placeholder?: string;
    readonly?: boolean;
    required?: boolean;
    style?: CSSProperties;
    getErrorMessage?: (value: string) => string;
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
    cypressAttribute: string;
}

const TextInput = ({ dark, disabled, placeholder, readonly, required, label, name, style, value, getErrorMessage, onBlur, onChange, cypressAttribute }: InputProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value, event.target.name);
    };

    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            inputRef.current?.blur();
        }
    };

    const errorMessage = getErrorMessage ? getErrorMessage(value) : '';

    return (
        <div className={`text-input ${dark ? 'dark' : ''}`} style={style}>
            <div className='text-input-field'>
                <input
                    ref={inputRef}
                    className={errorMessage ? 'error' : ''}
                    type='text' name={name}
                    placeholder={placeholder}
                    value={value}
                    disabled={disabled}
                    readOnly={readonly}
                    required={required}
                    onBlur={onBlur}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                    data-cy={cypressAttribute}
                />
                <div className={`text-input-label ${placeholder || Boolean(value) ? 'filled' : ''}`}>
                    {label}
                </div>
            </div>
            {getErrorMessage &&
                <div className='text-input-error'>
                    {errorMessage ? errorMessage : <br />}
                </div>
            }
        </div>
    );
};

export default TextInput;
