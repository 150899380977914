import React, { KeyboardEvent, ReactNode } from 'react';
import { IconType } from '../../enums';
import Icon from './Icon';
import { useOpenAnimations } from '../../hooks/common';

interface AccordionProps {
    children: ReactNode;
    label: string;
    open: boolean;
    onClick: () => void;
    closedLabel?: string;
    dark?: boolean;
    icon?: IconType;
}

const Accordion = ({ children, closedLabel, dark, icon, label, open, onClick }: AccordionProps) => {
    const [animationClass, handleAnimationEnd, contentRef, height] = useOpenAnimations<HTMLDivElement>(open);

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === ' ') {
            event.preventDefault();
            onClick();
        }
    };

    return (
        <div className={`accordion  ${animationClass} ${dark ? 'dark' : ''}`} tabIndex={0} onKeyDown={handleKeyDown}>
            <div className={`header ${icon != null ? 'with-icon' : ''}`} onClick={onClick}>
                {icon != null &&
                    <div className='header-icon'>
                        <Icon type={icon} />
                    </div>
                }
                <div className='header-label-container'>
                    {closedLabel &&
                        <div className='header-closed-label'>
                            {closedLabel}
                        </div>
                    }
                    <div className='header-label'>
                        {label}
                    </div>
                </div>
                <div className='header-close-icon' />

            </div>
            <div ref={contentRef} className='header-content-container' style={{ height }} onAnimationEnd={handleAnimationEnd}>
                <div className='header-content'>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Accordion;
