import React, { KeyboardEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { HeaderTabType, IconType } from '../../enums';
import { useAppSelector, useResized, useScrolledPast, useTranslate } from '../../hooks/common';
import HeaderAction, { HeaderActionProps, HeaderDropdownAction } from './HeaderAction';
import HeaderMegaMenu from './HeaderMegaMenu';
import HeaderTabs, { Tab } from './HeaderTabs';
import HeaderTagBarManager from '../application/HeaderTagBarManager';
import Banners from '../common/Banners';
import ContentContainer from '../common/ContentContainer';
import MenuIcon from '../common/MenuIcon';
import siemensLogo from '../../../assets/images/siemens-logos/siemens-logo-petrol.svg';
import { removeBanner, setLanguage } from '../../store';
import { Language } from '../../enums';
import { TRANSLATIONS } from '../../constants';

interface HeaderProps {
    title?: string;
}

const Header = ({ title }: HeaderProps) => {
    const dispatch = useDispatch();
    const banners = useAppSelector(state => state.banners.banners);
    const [menuOpen, setMenuOpen] = useState(false);
    const mobileView = useResized(x => x <= 767);
    const scrolled = useScrolledPast();
    const scrolledPastTagbar = useScrolledPast(410);
    const scrolledPastTagbarMobile = useScrolledPast(370);
    const translate = useTranslate();
    const languages = Object.keys(Language).slice(0, Math.ceil(Object.keys(Language).length / 2)).map(x => Number(x));
    type ObjectKey = keyof typeof TRANSLATIONS.header.languages;

    const handleSkipButtonKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();

            // eslint-disable-next-line no-restricted-globals
            location.hash = '';
            // eslint-disable-next-line no-restricted-globals
            location.hash = '#main';
        }
    };

    const handleLanguageChange = (language: Language) => {
        dispatch(setLanguage(language));
        window.localStorage.setItem('language', language.toString());
    };

    const headerActions: HeaderActionProps[] = [
        {
            dropdownActions: languages.map<HeaderDropdownAction>(x => {
                const key = Language[x].toLowerCase() as ObjectKey;
                return { name: translate(TRANSLATIONS.header.languages[key]), onClick: () => handleLanguageChange(x) };
            }),
            iconType: IconType.Language,
            title: translate(TRANSLATIONS.header.language)
        },
        {
            dropdownActions: [
                { name: translate(TRANSLATIONS.header.reportProblem), iconType: IconType.Email, onClick: () => window.location.href = 'mailto:apps-help.industry@siemens.com' },
                { name: translate(TRANSLATIONS.header.eBusiness), iconType: IconType.GroupPeople, onClick: () => window.open('https://mall.industry.siemens.com/aspa_app/?cntryid=WW&lang=en', '_blank', 'noopener') }
            ],
            iconType: IconType.Support,
            title: translate(TRANSLATIONS.header.support)
        }
    ];

    const tabs: Tab[] = [
        { title: translate(TRANSLATIONS.header.easySelectionSimoticsLVMotors), to: '/', type: HeaderTabType.Selector }
    ];

    return (
        <header>
            <div className='main-row-container'>
                <ContentContainer header>
                    <div className='main-row'>
                        <div className='skip-button' tabIndex={0} onKeyDown={handleSkipButtonKeyDown}>
                            {translate(TRANSLATIONS.header.skipToMainContent)}
                        </div>
                        <div className='header-row-container'>
                            <img className='siemens-logo' src={siemensLogo} alt='Siemens Logo' />
                        </div>
                        {title &&
                            <div className='header-row-container'>
                                <div className='title'>{title}</div>
                            </div>
                        }
                        <div className='action-container'>
                            {headerActions.map(x => <HeaderAction key={x.title} dropdownActions={x.dropdownActions} iconType={x.iconType} title={x.title} />)}
                            <div className='header-row-container'>
                                <MenuIcon open={menuOpen} onClick={() => setMenuOpen(x => !x)} />
                            </div>
                        </div>
                    </div>
                </ContentContainer>
            </div>
            <div className={`addtitional-rows-container ${scrolled ? 'hiden' : ''}`}>
                <div className='secondary-row-container'>
                    <ContentContainer header>
                        <div className='secondary-row'>
                            <HeaderTabs hidden={scrolled || mobileView} tabs={tabs} />
                        </div>
                    </ContentContainer>
                </div>
                {(mobileView ? scrolledPastTagbarMobile : scrolledPastTagbar) &&
                    <div className='tag-bar-container'>
                        <ContentContainer>
                            <HeaderTagBarManager />
                        </ContentContainer>
                    </div>
                }
                <Banners banners={banners} removeBanner={x => dispatch(removeBanner(x))} />
            </div>
            <HeaderMegaMenu navigationItems={tabs.map(x => ({ ...x }))} open={menuOpen} onClose={() => setMenuOpen(false)} />
        </header>
    );
};

export default Header;
