import React from 'react';
import { EfficiencyClass } from '../../enums';

interface EfficiencySymbolProps {
    efficiencyClass: EfficiencyClass;
}

const EfficiencySymbol = ({ efficiencyClass }: EfficiencySymbolProps) => {
    const getEfficiencyClass = () => {
        switch (efficiencyClass) {
            case EfficiencyClass.IE1:
                return 'ie1';
            case EfficiencyClass.IE2:
                return 'ie2';
            case EfficiencyClass.IE3:
                return 'ie3';
            case EfficiencyClass.IE4:
                return 'ie4';
            case EfficiencyClass.NA:
                return 'na';
            case EfficiencyClass.NANA:
                return 'nana';
        }
    };

    return (
        <div className={`efficiency-symbol ${getEfficiencyClass()}`} />
    );
};

export default EfficiencySymbol;
