import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import Layout from './ts/components/layout/Layout';
import SelectorManager from './ts/components/application/SelectorManager';

const routes = (
    <Routes>
        <Route path='/*' element={<Layout><Outlet /></Layout>}>
            <Route index element={<SelectorManager />} />
        </Route>
    </Routes>
);

export default routes;
