import { ApplicationInsights, IEventTelemetry } from '@microsoft/applicationinsights-web';
import { Api, apiCall } from '.';
import { OptionData } from '../models';

export const getEnvironment = () => apiCall(
    Api.isProduction(),
    async x => {
        const windowAsAny = window as any;
        windowAsAny.isProduction = x.data;
    },
    async () => { }
);

export const getTrackData = (name: string, trackData: Record<string, unknown>, optionData?: OptionData) => {
    const data = { name: name, properties: {} };

    if (trackData) {
        data.properties = { ...data.properties, ...trackData };
    }

    const optionDataArray = optionData?.optionGroups.flatMap(x => x.options.filter(y => y.selected).map(y => y.name));

    if ((optionDataArray ?? []).length > 0) {
        data.properties = { 
            ...data.properties, 
            options: optionDataArray?.join('+') 
        };
    }

    return data;
};

export const trackEvent = (event: IEventTelemetry) => {
    const windowAsAny = window as any;
    const applicationInsights: ApplicationInsights | null = windowAsAny.isProduction ? windowAsAny.appInsights : null;

    try {
        applicationInsights?.trackEvent(event);
    } catch (e) { }
};
