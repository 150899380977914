import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { getEnvironment } from './ts/services';
import routes from './routes';
import 'rc-slider/assets/index.css';
import './css/styles.css';
import { BrowserRouter } from 'react-router-dom';
import { store } from './ts/store/store';

getEnvironment();

const container = document.getElementById('root') ?? new HTMLElement;
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <Provider store={store}>
            <BrowserRouter>
                {routes}
            </BrowserRouter>
        </Provider>
    </Provider>
);
