import React, { ReactNode } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

interface TooltipProps {
    children: ReactNode;
    id: string;
    text: string;
    hidden?: boolean;
    delayShow?: number;
    delayHide?: number;
    openOnClick?: boolean;
    place?: 'top' | 'right' | 'bottom' | 'left'
    afterShow?: () => void;
}

const Tooltip = ({ children, afterShow, delayShow, delayHide, hidden, id, place, text, openOnClick }: TooltipProps) => (
    <div className='tooltip' data-tooltip-id={id} data-tooltip-content={text}>
        {children}
        {!hidden &&
            <ReactTooltip
                id={id}
                place={place}
                delayShow={delayShow ?? 1000}
                delayHide={delayHide ?? 200}
                openOnClick={openOnClick ?? false}
                afterShow={afterShow}
                style={{ backgroundColor: '#000028' }}
            />
        }
    </div>
);

export default Tooltip;
