import React from 'react';
import { useDispatch } from 'react-redux';
import { apiCall, Api, mergeOptionData } from '../../services';
import { Filter, OptionValidationRequest } from '../../models';
import SelectorTagBar from './SelectorTagBar';
import { SectionColor } from '../common/Section';
import { SnackbarType } from '../common/Snackbar';
import { useAppSelector, useTranslate } from '../../hooks/common';
import { TRANSLATIONS } from '../../constants';
import { toggleLoading, setMotors, clearHiddenIds, clearNewIds, addSnackbar, setOptionData } from '../../store';

const HeaderTagBarManager = () => {
    const dispatch = useDispatch();
    const optionData = useAppSelector(state => state.selector.optionData);
    const translate = useTranslate();
    const _toggleLoading = () => dispatch(toggleLoading());

    const handleSecondaryFilter = async (filter: Filter) => {
        _toggleLoading();

        await apiCall(
            Api.secondaryFilter(filter),
            async x => {
                dispatch(setMotors(x.data.motors));
                dispatch(clearHiddenIds());
                dispatch(clearNewIds());
            },
            async () => {
                dispatch(addSnackbar({
                    text: translate(TRANSLATIONS.error.filter), type: SnackbarType.Error }));
            }
        );

        _toggleLoading();
    };

    const handleOptionValidation = async (request: OptionValidationRequest) => {
        _toggleLoading();

        await apiCall(
            Api.optionValidation(request),
            async x => {
                optionData && dispatch(setOptionData(mergeOptionData(optionData, x.data)));
            },
            async () => {
                dispatch(addSnackbar({
                    text: translate(TRANSLATIONS.error.optionValidation), type: SnackbarType.Error }));
            }
        );

        _toggleLoading();
    };

    return (
        <SelectorTagBar backgroundColor={SectionColor.White} onOptionValidation={handleOptionValidation} onSecondaryFilter={handleSecondaryFilter} />
    );
};

export default HeaderTagBarManager;
