import { Motor, Option, OptionData, OptionValidationRequest, OptionMlfb } from '../models';

export const getOptionValidationRequest = (motors: Motor[], name: string, optionData?: OptionData): OptionValidationRequest => {
    const alreadySelectedOptions = getSelectedOptionNames(optionData);
    const selectedOptions = alreadySelectedOptions.includes(name) ? alreadySelectedOptions.filter(x => x !== name) : [...alreadySelectedOptions, name];

    return { mlfbs: motors.map<OptionMlfb>(x => ({ mlfb: x.mlfb, frameSize: x.frameSize.toString() })), options: selectedOptions.sort() };
};

export const getSelectedOptions = (optionData: OptionData | undefined) => optionData?.optionGroups.flatMap(x => x.options).filter(x => x.selected) ?? [];

export const getSelectedOptionNames = (optionData: OptionData | undefined) => getSelectedOptions(optionData).map(x => x.name);

export const isOptionValid = (option: Option, motors: Motor[]) =>
    motors.some(x => !option.implicitMlfbs.includes(x.mlfb)) && motors.length > 0 && motors.some(x => option.validMlfbs.includes(x.mlfb));

export const mergeOptionData = (currentOptionData: OptionData | undefined, newOptionData: OptionData): OptionData =>
    ({ ...newOptionData, optionGroups: newOptionData.optionGroups.map((x, i) => ({ ...x, open: currentOptionData?.optionGroups[i]?.open ?? false })) });

export const setSelectedOption = (optionData: OptionData, name: string): OptionData =>
    ({ ...optionData, optionGroups: optionData.optionGroups.map(x => ({ ...x, options: x.options.map(y => y.name === name ? { ...y, selected: !y.selected } : y) })) });

export const toggleOptionGroup = (optionData: OptionData, index: number): OptionData =>
    ({ ...optionData, optionGroups: optionData.optionGroups.map((x, i) => i === index ? { ...x, open: !x.open } : x) });
