import React, { KeyboardEvent } from 'react';
import { IconType } from '../../enums';
import Icon from './Icon';

export interface TagProps {
    text: string;
    close?: boolean;
    dark?: boolean;
    disabled?: boolean;
    onClick?: () => void;
}

const Tag = ({ close, dark, disabled, text, onClick }: TagProps) => {
    const handleSelect = () => {
        if (onClick && !disabled) {
            onClick();
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === ' ') {
            event.preventDefault();
            handleSelect();
        }
    };

    return (
        <div 
            className={`tag ${disabled ? 'disabled' : ''} ${onClick ? '' : 'display'} ${dark ? 'dark' : ''}`}
            tabIndex={onClick && !disabled ? 0 : undefined}
            onClick={handleSelect}
            onKeyDown={handleKeyDown}
        >
            <div className='tag-text'>{text}</div>
            {close && <Icon type={IconType.CloseSmall} />}
        </div>
    );
};

export default Tag;
