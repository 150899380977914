import React, { ReactNode } from 'react';
import { Drawer } from '@material-ui/core';
import Button, { ButtonType } from './Button';
import Icon from './Icon';
import { IconType } from '../../enums';

interface SideSheetProps {
    open: boolean;
    title: string;
    onClose: () => void;
    actions?: { children: JSX.Element, key: string, onClick: () => void }[];
    children?: ReactNode;
}

const SideSheet = ({ actions, children, open, title, onClose }: SideSheetProps) => (
    <Drawer anchor='right' open={open} classes={{ paper: 'side-sheet' }} onClose={onClose} data-cy='side-sheet'>
        <div className='side-sheet-header'>
            <Button type={ButtonType.Tertiary} data-cy='close-side-sheet' onClick={onClose}>
                <Icon type={IconType.Close} />
            </Button>
            <div className='title'>
                {title}
            </div>
            <div className='actions'>
                {actions && actions.map(x =>
                    <Button key={x.key} type={ButtonType.Tertiary} onClick={x.onClick}>
                        {x.children}
                    </Button>
                )}
            </div>
        </div>
        <div className='side-sheet-content'>
            {children}
        </div>
    </Drawer>
);

export default SideSheet;
