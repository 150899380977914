import { Translations } from '../models';

const getTranslations = <T extends Translations>(translations: T): T => translations;

export const TRANSLATIONS = getTranslations({
    'error': {
        'availableValues': [
            'Cannot load available values!',
            'Cannot load available values!',
            'Cannot load available values!',
            'Cannot load available values!',
            'Cannot load available values!',
            'Cannot load available values!',
            'Cannot load available values!',
            'Cannot load available values!',
            'Cannot load available values!',
            'Cannot load available values!',
            'Cannot load available values!',
            'Cannot load available values!'
        ],
        'filter': [
            'Cannot filter!',
            'Cannot filter!',
            'Cannot filter!',
            'Cannot filter!',
            'Cannot filter!',
            'Cannot filter!',
            'Cannot filter!',
            'Cannot filter!',
            'Cannot filter!',
            'Cannot filter!',
            'Cannot filter!',
            'Cannot filter!'
        ],
        'optionValidation': [
            'Cannot validate options!',
            'Cannot validate options!',
            'Cannot validate options!',
            'Cannot validate options!',
            'Cannot validate options!',
            'Cannot validate options!',
            'Cannot validate options!',
            'Cannot validate options!',
            'Cannot validate options!',
            'Cannot validate options!',
            'Cannot validate options!',
            'Cannot validate options!'
        ],
        'commercialData': [
            'Cannot load commercial data!',
            'Cannot load commercial data!',
            'Cannot load commercial data!',
            'Cannot load commercial data!',
            'Cannot load commercial data!',
            'Cannot load commercial data!',
            'Cannot load commercial data!',
            'Cannot load commercial data!',
            'Cannot load commercial data!',
            'Cannot load commercial data!',
            'Cannot load commercial data!',
            'Cannot load commercial data!'
        ]
    },
    'footer': {
        'contactUs': [
            'Contact us',
            'Kontakt',
            'Contact us',
            'Contact us',
            'Contact us',
            'Contact us',
            'Contact us',
            'Contact us',
            'Contact us',
            'Contact us',
            'Contact us',
            'Contact us'
        ],
        'contactUsLink': [
            'http://new.siemens.com/global/en/general/contact.html',
            'https://new.siemens.com/global/de/general/kontakt.html',
            'http://new.siemens.com/global/en/general/contact.html',
            'http://new.siemens.com/global/en/general/contact.html',
            'http://new.siemens.com/global/en/general/contact.html',
            'http://new.siemens.com/global/en/general/contact.html',
            'http://new.siemens.com/global/en/general/contact.html',
            'http://new.siemens.com/global/en/general/contact.html',
            'http://new.siemens.com/global/en/general/contact.html',
            'http://new.siemens.com/global/en/general/contact.html',
            'http://new.siemens.com/global/en/general/contact.html',
            'http://new.siemens.com/global/en/general/contact.html'
        ],
        'cookieNotice': [
            'Cookie Notice',
            'Cookie-Hinweise',
            'Cookie Notice',
            'Cookie Notice',
            'Cookie Notice',
            'Cookie Notice',
            'Cookie Notice',
            'Cookie Notice',
            'Cookie Notice',
            'Cookie Notice',
            'Cookie Notice',
            'Cookie Notice'
        ],
        'cookieNoticeLink': [
            'https://new.siemens.com/global/en/general/cookie-notice.html',
            'https://new.siemens.com/global/de/general/cookie-richtlinien.html',
            'https://new.siemens.com/global/en/general/cookie-notice.html',
            'https://new.siemens.com/global/en/general/cookie-notice.html',
            'https://new.siemens.com/global/en/general/cookie-notice.html',
            'https://new.siemens.com/global/en/general/cookie-notice.html',
            'https://new.siemens.com/global/en/general/cookie-notice.html',
            'https://new.siemens.com/global/en/general/cookie-notice.html',
            'https://new.siemens.com/global/en/general/cookie-notice.html',
            'https://new.siemens.com/global/en/general/cookie-notice.html',
            'https://new.siemens.com/global/en/general/cookie-notice.html',
            'https://new.siemens.com/global/en/general/cookie-notice.html'
        ],
        'corporateInformation': [
            'Corporate Information',
            'Impressum',
            'Corporate Information',
            'Corporate Information',
            'Corporate Information',
            'Corporate Information',
            'Corporate Information',
            'Corporate Information',
            'Corporate Information',
            'Corporate Information',
            'Corporate Information',
            'Corporate Information'
        ],
        'corporateInformationLink': [
            'https://new.siemens.com/global/en/general/legal.html',
            'https://new.siemens.com/global/de/general/legal.html',
            'https://new.siemens.com/global/en/general/legal.html',
            'https://new.siemens.com/global/en/general/legal.html',
            'https://new.siemens.com/global/en/general/legal.html',
            'https://new.siemens.com/global/en/general/legal.html',
            'https://new.siemens.com/global/en/general/legal.html',
            'https://new.siemens.com/global/en/general/legal.html',
            'https://new.siemens.com/global/en/general/legal.html',
            'https://new.siemens.com/global/en/general/legal.html',
            'https://new.siemens.com/global/en/general/legal.html',
            'https://new.siemens.com/global/en/general/legal.html'
        ],
        'digitalId': [
            'Digital ID',
            'Digitales Zertifikat',
            'Digital ID',
            'Digital ID',
            'Digital ID',
            'Digital ID',
            'Digital ID',
            'Digital ID',
            'Digital ID',
            'Digital ID',
            'Digital ID',
            'Digital ID'
        ],
        'digitalIdLink': [
            'https://new.siemens.com/global/en/general/digital-id.html',
            'https://new.siemens.com/global/de/general/digitales-zertifikat.html',
            'https://new.siemens.com/global/en/general/digital-id.html',
            'https://new.siemens.com/global/en/general/digital-id.html',
            'https://new.siemens.com/global/en/general/digital-id.html',
            'https://new.siemens.com/global/en/general/digital-id.html',
            'https://new.siemens.com/global/en/general/digital-id.html',
            'https://new.siemens.com/global/en/general/digital-id.html',
            'https://new.siemens.com/global/en/general/digital-id.html',
            'https://new.siemens.com/global/en/general/digital-id.html',
            'https://new.siemens.com/global/en/general/digital-id.html',
            'https://new.siemens.com/global/en/general/digital-id.html'
        ],
        'siemensWebsite': [
            'siemens.com Global Website',
            'siemens.com Germany',
            'siemens.com Global Website',
            'siemens.com Global Website',
            'siemens.com Global Website',
            'siemens.com Global Website',
            'siemens.com Global Website',
            'siemens.com Global Website',
            'siemens.com Global Website',
            'siemens.com Global Website',
            'siemens.com Global Website',
            'siemens.com Global Website'
        ],
        'siemensWebsiteLink': [
            'https://new.siemens.com/global/en.html',
            'https://new.siemens.com/global/de.html',
            'https://new.siemens.com/global/en.html',
            'https://new.siemens.com/global/en.html',
            'https://new.siemens.com/global/en.html',
            'https://new.siemens.com/global/en.html',
            'https://new.siemens.com/global/en.html',
            'https://new.siemens.com/global/en.html',
            'https://new.siemens.com/global/en.html',
            'https://new.siemens.com/global/en.html',
            'https://new.siemens.com/global/en.html',
            'https://new.siemens.com/global/en.html'
        ],
        'privacyNotice': [
            'Privacy Notice',
            'Datenschutz',
            'Privacy Notice',
            'Privacy Notice',
            'Privacy Notice',
            'Privacy Notice',
            'Privacy Notice',
            'Privacy Notice',
            'Privacy Notice',
            'Privacy Notice',
            'Privacy Notice',
            'Privacy Notice'
        ],
        'privacyNoticeLink': [
            'https://new.siemens.com/global/en/general/privacy-notice.html',
            'https://new.siemens.com/global/de/general/datenschutz.html',
            'https://new.siemens.com/global/en/general/privacy-notice.html',
            'https://new.siemens.com/global/en/general/privacy-notice.html',
            'https://new.siemens.com/global/en/general/privacy-notice.html',
            'https://new.siemens.com/global/en/general/privacy-notice.html',
            'https://new.siemens.com/global/en/general/privacy-notice.html',
            'https://new.siemens.com/global/en/general/privacy-notice.html',
            'https://new.siemens.com/global/en/general/privacy-notice.html',
            'https://new.siemens.com/global/en/general/privacy-notice.html',
            'https://new.siemens.com/global/en/general/privacy-notice.html',
            'https://new.siemens.com/global/en/general/privacy-notice.html'
        ],
        'siemens': [
            '© Siemens, 1996 - {date}',
            '© Siemens, 1996 - {date}',
            '© Siemens, 1996 - {date}',
            '© Siemens, 1996 - {date}',
            '© Siemens, 1996 - {date}',
            '© Siemens, 1996 - {date}',
            '© Siemens, 1996 - {date}',
            '© Siemens, 1996 - {date}',
            '© Siemens, 1996 - {date}',
            '© Siemens, 1996 - {date}',
            '© Siemens, 1996 - {date}',
            '© Siemens, 1996 - {date}'
        ],
        'termsOfUse': [
            'Terms of use',
            'Nutzungsbedingungen',
            'Terms of use',
            'Terms of use',
            'Terms of use',
            'Terms of use',
            'Terms of use',
            'Terms of use',
            'Terms of use',
            'Terms of use',
            'Terms of use',
            'Terms of use'
        ],
        'termsOfUseLink': [
            'https://new.siemens.com/global/en/general/terms-of-use.html',
            'https://new.siemens.com/global/de/general/nutzungsbedingungen.html',
            'https://new.siemens.com/global/en/general/terms-of-use.html',
            'https://new.siemens.com/global/en/general/terms-of-use.html',
            'https://new.siemens.com/global/en/general/terms-of-use.html',
            'https://new.siemens.com/global/en/general/terms-of-use.html',
            'https://new.siemens.com/global/en/general/terms-of-use.html',
            'https://new.siemens.com/global/en/general/terms-of-use.html',
            'https://new.siemens.com/global/en/general/terms-of-use.html',
            'https://new.siemens.com/global/en/general/terms-of-use.html',
            'https://new.siemens.com/global/en/general/terms-of-use.html',
            'https://new.siemens.com/global/en/general/terms-of-use.html'
        ]
    },
    'header': {
        'easySelectionSimoticsLVMotors': [
            'Easy Selection SIMOTICS LV-motors',
            'Easy Selection SIMOTICS LV-motors',
            'Easy Selection SIMOTICS LV-motors',
            'Easy Selection SIMOTICS LV-motors',
            'Easy Selection SIMOTICS LV-motors',
            'Easy Selection SIMOTICS LV-motors',
            'Easy Selection SIMOTICS LV-motors',
            'Easy Selection SIMOTICS LV-motors',
            'Easy Selection SIMOTICS LV-motors',
            'Easy Selection SIMOTICS LV-motors',
            'Easy Selection SIMOTICS LV-motors',
            'Easy Selection SIMOTICS LV-motors'
        ],
        'eBusiness': [
            'E-Business',
            'E-Business',
            'E-Business',
            'E-Business',
            'E-Business',
            'E-Business',
            'E-Business',
            'E-Business',
            'E-Business',
            'E-Business',
            'E-Business',
            'E-Business'
        ],
        'language': [
            'Language',
            'Sprache',
            'Language',
            'Language',
            'Language',
            'Language',
            'Language',
            'Language',
            'Language',
            'Language',
            'Language',
            'Language'
        ],
        'languages': {
            'ch': [
                'Czech',
                'Tschechisch',
                'Czech',
                'Czech',
                'Czech',
                'Czech',
                'Czech',
                'Czech',
                'Czech',
                'Czech',
                'Czech',
                'Czech'
            ],
            'cn': [
                'Chinese',
                'Chinesisch',
                'Chinese',
                'Chinese',
                'Chinese',
                'Chinese',
                'Chinese',
                'Chinese',
                'Chinese',
                'Chinese',
                'Chinese',
                'Chinese'
            ],
            'de': [
                'German',
                'Deutsch',
                'German',
                'German',
                'German',
                'German',
                'German',
                'German',
                'German',
                'German',
                'German',
                'German'
            ],
            'en': [
                'English',
                'Englisch',
                'English',
                'English',
                'English',
                'English',
                'English',
                'English',
                'English',
                'English',
                'English',
                'English'
            ],
            'es': [
                'Spanish',
                'Spanisch',
                'Spanish',
                'Spanish',
                'Spanish',
                'Spanish',
                'Spanish',
                'Spanish',
                'Spanish',
                'Spanish',
                'Spanish',
                'Spanish'
            ],
            'fr': [
                'French',
                'Französisch',
                'French',
                'French',
                'French',
                'French',
                'French',
                'French',
                'French',
                'French',
                'French',
                'French'
            ],
            'it': [
                'Italian',
                'Italienisch',
                'Italian',
                'Italian',
                'Italian',
                'Italian',
                'Italian',
                'Italian',
                'Italian',
                'Italian',
                'Italian',
                'Italian'
            ],
            'nl': [
                'Dutch',
                'Niederländisch',
                'Dutch',
                'Dutch',
                'Dutch',
                'Dutch',
                'Dutch',
                'Dutch',
                'Dutch',
                'Dutch',
                'Dutch',
                'Dutch'
            ],
            'pl': [
                'Polish',
                'Polieren',
                'Polish',
                'Polish',
                'Polish',
                'Polish',
                'Polish',
                'Polish',
                'Polish',
                'Polish',
                'Polish',
                'Polish'
            ],
            'pt': [
                'Portuguese',
                'Portugiesisch',
                'Portuguese',
                'Portuguese',
                'Portuguese',
                'Portuguese',
                'Portuguese',
                'Portuguese',
                'Portuguese',
                'Portuguese',
                'Portuguese',
                'Portuguese'
            ],
            'ru': [
                'Russian',
                'Russisch',
                'Russian',
                'Russian',
                'Russian',
                'Russian',
                'Russian',
                'Russian',
                'Russian',
                'Russian',
                'Russian',
                'Russian'
            ],
            'tr': [
                'Turkish',
                'Türkisch',
                'Turkish',
                'Turkish',
                'Turkish',
                'Turkish',
                'Turkish',
                'Turkish',
                'Turkish',
                'Turkish',
                'Turkish',
                'Turkish'
            ]
        },
        'skipToMainContent': [
            'Skip to main content',
            'Skip to main content',
            'Skip to main content',
            'Skip to main content',
            'Skip to main content',
            'Skip to main content',
            'Skip to main content',
            'Skip to main content',
            'Skip to main content',
            'Skip to main content',
            'Skip to main content',
            'Skip to main content',
            'Skip to main content',
            'Skip to main content',
            'Skip to main content'
        ],
        'support': [
            'Support',
            'Unterstützung',
            'Support',
            'Support',
            'Support',
            'Support',
            'Support',
            'Support',
            'Support',
            'Support',
            'Support',
            'Support'
        ],
        'reportProblem': [
            'Report a problem',
            'Ein Problem melden',
            'Report a problem',
            'Report a problem',
            'Report a problem',
            'Report a problem',
            'Report a problem',
            'Report a problem',
            'Report a problem',
            'Report a problem',
            'Report a problem',
            'Report a problem'
        ]
    },
    'main': {
        'addToCart': [
            'Add to cart',
            'In den Warenkorb',
            'Añadir al carrito',
            'Ajouter au panier',
            'Aggiungi al carrello',
            'Adicionar ao carrinho',
            'Добавить',
            '加入购物车',
            'Sepete ekle',
            'Aan winkelwagen toevoegen',
            'Dodaj do koszyka',
            'Přidat do košíku'
        ],
        'allFilters': [
            'All filters',
            'All filters',
            'All filters',
            'All filters',
            'All filters',
            'All filters',
            'All filters',
            'All filters',
            'All filters',
            'All filters',
            'All filters',
            'All filters'
        ],
        'basicFilter': [
            'Basic Filter',
            'Basic Filter',
            'Basic Filter',
            'Basic Filter',
            'Basic Filter',
            'Basic Filter',
            'Basic Filter',
            'Basic Filter',
            'Basic Filter',
            'Basic Filter',
            'Basic Filter',
            'Basic Filter'
        ],
        'configure': [
            'Configure',
            'Konfigurieren',
            'configurar',
            'configurer',
            'Configurare',
            'Configurar',
            'конфигурировать',
            '配置',
            'Konfigürasyon',
            'Configureren',
            'Skonfiguruj',
            'Konfigurovat'
        ],
        'dataSheetAndDrawing': [
            'Datasheet & Drawing',
            'Datenblatt & Maßbild',
            'Hoja de datos y plano',
            'Fiche technique et dessin',
            'Foglio dati e disegno',
            'Folha de dados & Desenho',
            'Тех. паспорт и чертеж',
            '数据表和图纸',
            'Veri Sayfası ve Çizim',
            'Datablad & Tekening',
            'Karta danych i rysunek',
            'Údajový list a výkresy'
        ],
        'days': [
            'days',
            'Tage',
            'días',
            'jours',
            'giorni',
            'dias',
            'дн.',
            '天',
            'gün',
            'dagen',
            'dni',
            'dní'
        ],
        'deliveryTime': [
            'Delivery time',
            'Delivery time',
            'Delivery time',
            'Delivery time',
            'Delivery time',
            'Delivery time',
            'Delivery time',
            'Delivery time',
            'Delivery time',
            'Delivery time',
            'Delivery time',
            'Delivery time'
        ],
        'listPrice': [
            'List price',
            'List price',
            'List price',
            'List price',
            'List price',
            'List price',
            'List price',
            'List price',
            'List price',
            'List price',
            'List price',
            'List price'
        ],
        'onRequest': [
            'On request',
            'On request',
            'On request',
            'On request',
            'On request',
            'On request',
            'On request',
            'On request',
            'On request',
            'On request',
            'On request',
            'On request'
        ],
        'efficiency': [
            'Efficiency',
            'Effizienz',
            'Eficiencia',
            'Rendement',
            'Efficienza',
            'Eficiência',
            'КПД',
            '能效',
            'Verim',
            'Efficiëntie',
            'Sprawność',
            'Účinnost'
        ],
        'efficiencyClass': [
            'Efficiency Class',
            'Effizienzklasse',
            'Clase de eficiencia',
            'Classe de rendement',
            'Classe di efficienza',
            'Classe de eficiência',
            'Класс эффективности',
            '能效等级',
            'Verim sınıfı',
            'Efficiëntieklasse',
            'Klasa sprawności',
            'Třída účinnosti'
        ],
        'filterActive': [
            'Filter active',
            'Filter aktiv',
            'Filtro activo',
            'Filtre actif',
            'Filtro attivo',
            'Filtro ativo',
            'Фильтр активен',
            '过滤有效',
            'Aktif filtre',
            'Filter actief',
            'Filtr aktywny',
            'Filtr je aktivní'
        ],
        'iain': [
            'Ia/In',
            'Ia/In',
            'Ia/In',
            'Ia/In',
            'Ia/In',
            'Ca/Tn (Corrente de arranque/Tensão nominal)',
            'Iпуск/Iном',
            'Ia/In',
            'Ig/In (güncel/nominal)',
            'Ia/In',
            'Ir/Iz (rozruchowy/znamionowy)',
            'Ia/In'
        ],
        'less': [
            'less',
            'weniger',
            'menos',
            'moins',
            'meno',
            'menos',
            'свернуть',
            '收起',
            'daha az',
            'minder',
            'mniej',
            'méně'
        ],
        'line': [
            'Line',
            'Linie',
            'Línea',
            'Ligne',
            'Linea',
            'Linha',
            'Линия',
            '电网',
            'Seri',
            'Lijn',
            'Linia',
            'Line'
        ],
        'modify': [
            'Modify',
            'Modifizieren',
            'Modificar',
            'Modifier',
            'Modifica',
            'Modificar',
            'Изменить',
            '更改',
            'Değiştir',
            'Aanpassen',
            'Modyfikuj',
            'Změnit'
        ],
        'more': [
            'more',
            'mehr',
            'más',
            'plus',
            'più',
            'mais',
            'развернуть',
            '展开',
            'daha fazla',
            'meer',
            'więcej',
            'více'
        ],
        'motorFeatures': [
            'Motor Features',
            'Motordaten',
            'Características del motor',
            'Caractéristiques du moteur',
            'Caratteristiche motore',
            'Características do motor',
            'Характеристики двигателя',
            '电机性能',
            'Motorun Özellikleri',
            'Motorkenmerken',
            'Parametry silnika',
            'Vlastnosti motoru'
        ],
        'motorProtection': [
            'Motor Protection',
            'Motor Protection',
            'Motor Protection',
            'Motor Protection',
            'Motor Protection',
            'Motor Protection',
            'Motor Protection',
            'Motor Protection',
            'Motor Protection',
            'Motor Protection',
            'Motor Protection',
            'Motor Protection'
        ],
        'noResults': [
            'No results',
            'Keine Treffer',
            'sin resultados',
            'aucun résultat',
            'Nessun risultato',
            'Sem resultados',
            'нет результатов',
            '没有结果',
            'sonuç yok',
            'Geen resultaten',
            'Brak ofert spełniających podane kryteria',
            'Žádné výsledky'
        ],
        'pleaseEnterMotorData': [
            'Please enter motor data',
            'Bitte  Motordaten angeben',
            'Introducir los datos del motor',
            'Merci de saisir les paramètres moteur',
            'Immettere i dati motore',
            'Introduzir dados do motor',
            'Введите параметры двигателя',
            '请输入电机数据',
            'Lütfen motor verilerini girin',
            'Gelieve de motorgegevens in te geven',
            'Proszę wprowadzić dane silnika',
            'Zadejte prosím parametry motoru'
        ],
        'power': [
            'Power',
            'Leistung',
            'Potencia',
            'Puissance',
            'Potenza',
            'Potência',
            'Мощность',
            '功率',
            'Güç',
            'Vermogen',
            'Zasilanie',
            'Výkon'
        ],
        'rpm': [
            'rpm',
            '1/min',
            'rpm',
            'tr/min',
            '1/min',
            'rpm',
            'об/мин',
            'rpm',
            'devir/dakika',
            'rpm',
            'obr./min',
            'ot/min'
        ],
        'sizeOfMotor': [
            'Size of motor',
            'Baugröße des Motors',
            'Tamaño del motor',
            'Taille du moteur',
            'Dimensioni del motore',
            'Tamanho do motor',
            'Типоразмер двигателя',
            '电机尺寸',
            'Motor boyutu',
            'Motorafmeting',
            'Wielkość silnika',
            'Velikost motoru'
        ],
        'spFilter': [
            'SP filter',
            'SP filter',
            'SP filter',
            'SP filter',
            'SP filter',
            'SP filter',
            'SP filter',
            'SP filter',
            'SP filter',
            'SP filter',
            'SP filter',
            'SP filter'
        ],
        'synchronousSpeed': [
            'Synchronous Speed',
            'Synchrondrehzahl',
            'Velocidad síncrona',
            'Vitesse synchrone',
            'N. di giri sincrono',
            'Velocidade síncrona',
            'Синхронная частота вращения',
            '同步转速',
            'Senkron Hız',
            'Synchrone snelheid',
            'Prędkość synchroniczna',
            'Synchronní otáčky'
        ],
        'terminalBoxPosition': [
            'Terminal box position',
            'Terminal box position',
            'Terminal box position',
            'Terminal box position',
            'Terminal box position',
            'Terminal box position',
            'Terminal box position',
            'Terminal box position',
            'Terminal box position',
            'Terminal box position',
            'Terminal box position',
            'Terminal box position'
        ],
        'torque': [
            'Torque',
            'Drehmoment',
            'Par',
            'Couple',
            'Coppia',
            'Torque',
            'Крутящий момент',
            '转矩',
            'Tork',
            'Koppel',
            'Moment obrotowy',
            'Moment'
        ],
        'typeOfConstruction': [
            'Type of construction',
            'Type of construction',
            'Type of construction',
            'Type of construction',
            'Type of construction',
            'Type of construction',
            'Type of construction',
            'Type of construction',
            'Type of construction',
            'Type of construction',
            'Type of construction',
            'Type of construction'
        ],
        'winding': [
            'Winding',
            'Winding',
            'Winding',
            'Winding',
            'Winding',
            'Winding',
            'Winding',
            'Winding',
            'Winding',
            'Winding',
            'Winding',
            'Winding'
        ],
        'productGroup': [
            'Product Group',
            'Produktreihe',
            'Product Group',
            'Product Group',
            'Product Group',
            'Product Group',
            'Product Group',
            'Product Group',
            'Product Group',
            'Product Group',
            'Product Group',
            'Product Group'
        ],
        'clearFilter': [
            'Clear filter',
            'Clear filter',
            'Clear filter',
            'Clear filter',
            'Clear filter',
            'Clear filter',
            'Clear filter',
            'Clear filter',
            'Clear filter',
            'Clear filter',
            'Clear filter',
            'Clear filter'
        ],
        'copyMlfb': [
            'Click here to copy MLFB',
            'Click here to copy MLFB',
            'Click here to copy MLFB',
            'Click here to copy MLFB',
            'Click here to copy MLFB',
            'Click here to copy MLFB',
            'Click here to copy MLFB',
            'Click here to copy MLFB',
            'Click here to copy MLFB',
            'Click here to copy MLFB',
            'Click here to copy MLFB',
            'Click here to copy MLFB'
        ],
        'copied': [
            'Copied!',
            'Copied!',
            'Copied!',
            'Copied!',
            'Copied!',
            'Copied!',
            'Copied!',
            'Copied!',
            'Copied!',
            'Copied!',
            'Copied!',
            'Copied!'
        ]
    },
    'option': {
        'C02': [
            'VIK version',
            'VIK-Ausführung',
            'Diseño VIK',
            'Exécution VIK',
            'Esecuzione VIK',
            'Modelo VIK',
            'Исполнение VIK',
            'VIK 型',
            'VIK uygulaması',
            'VIK uitvoering',
            'Wersja wykonania: VIK',
            'VIK provedení'
        ],
        'D01': [
            'CCC China Compulsory Certification',
            'CCC China Compulsory Certification',
            'Certificado obligatorio China CCC',
            'CCC China Compulsory Certification',
            'CCC China Compulsory Certification',
            'CCC China Compulsory Certification',
            'Китайская система обязательной сертификации продукции CCC',
            'CCC 中国强制认证（3C 认证）',
            'CCC Çin Zorunlu Sertifika',
            'CCC China Compulsory Certification',
            'CCC Obowiązująca Certyfikacja w Chinach',
            'Povinná certifikace CCC v Číně'
        ],
        'D33': [
            'For Korea, certified according to KS C IEC 60034-2-1',
            'Für Korea zertifiziert nach KS C IEC 60034-2-1',
            'Certificado para Corea según KS C IEC 60034-2-1',
            'Certifié pour la Corée selon KS C CEI 60034-2-1',
            'Certificato per la Corea secondo KS C IEC 60034-2-1',
            'Certificado para a Coreia segundo KS C IEC 60034-2-1',
            'Сертификация для Кореи согласно KS C IEC 60034-2-1',
            '符合 KS C IEC 60034-2-1 认证（韩国）',
            'KS C IEC 60034-2-1’e göre Kore için sertifikalandırılmış',
            'Voor Korea, gecertificeerd volgens KS C IEC 60034-2-1',
            'Dla Korei, certyfikacja zgodnie z KS C IEC 60034-2-1',
            'Certifikace pro Koreu dle KS C IEC 60034-2-1'
        ],
        'D34': [
            'China Energy Efficiency Label',
            'China Energy Efficiency Label',
            'Etiqueta de eficiencia energética China',
            'Étiquette de rendement énergétique, Chine',
            'Etichetta China Energy Efficiency',
            'Selo de eficiência energética da China',
            'Табличка с данными энергетической эффективности, Китай',
            '中国能效标识',
            'Çin Enerji Verimliliği Etiketi',
            'China Energy Efficiency Label',
            'Chińska etykieta sprawności elektrycznej',
            'Štítek energetické účinnosti, Čína'
        ],
        'D40': [
            'Canadian regulations (CSA)',
            'Kanadische Vorschriften (CSA)',
            'Normas canadienses (CSA)',
            'Réglementation canadienne (CSA)',
            'Norme canadesi (CSA)',
            'Regulamentos canadianos (CSA)',
            'Канадские предписания (CSA)',
            '加拿大标准 (CSA)',
            'Kanada yönetmelikleri (CSA)',
            'Canadese voorschriften (CSA)',
            'Przepisy kanadyjskie (CSA)',
            'Kanadské předpisy (CSA)'
        ],
        'D31': [
            'Version according to UL with \'Recognition Mark\'',
            'Ausführung nach UL mit „Recognition Mark“',
            'Versión según UL con \'Recognition Mark\'',
            'Exécution selon UL avec marque de reconnaissance',
            'Esecuzione secondo UL con \'Recognition Mark\'',
            'Modelo segundo UL com \'Recognition Mark\'',
            'Исполнение по UL с «маркировкой»',
            'UL 型，带“认证标志”',
            'UL’ye uygun \'Belirtici işaret\'li uygulama',
            'Uitvoering volgens UL met „Recognition Mark“',
            'Wykonanie zgodnie z UL z „Recognition Mark“',
            'Provedení podle UL s „Recognition Mark“'
        ],
        'B01': [
            'With safety and commissioning information for each lattice pallet',
            'Mit einem Sicherheits- und Inbetriebnahmehinweis pro Gitterboxpalette',
            'Con unas instrucciones de seguridad y puesta en marcha por palé',
            'Avec consignes de sécurité et de mise en service par palette à claire-voie',
            'Con una indicazione di sicurezza e messa in servizio per ogni pallet a gabbia',
            'Com uma instrução de segurança e de colocação em funcionamento por palete-caixa de malha',
            'С указаниями по технике безопасности и вводу в эксплуатацию на каждый решетчатый поддон',
            '含安全和调试提示，每栅格托板',
            'Her kafes palete birer Emniyet ve Devreye Alma Notu ile',
            'Met een veiligheids- en inbedrijfsstellingsinstructie per tralieboxpallet',
            'Z informacjami dotyczącymi bezpieczeństwa i uruchomienia na każdej skrzyniopalecie',
            'S pokyny k bezpečnosti a uvedení do provozu pro každou mřížovou paletu'
        ],
        'D30': [
            'Electrical design according to NEMA  MG1-12',
            'Elektrisch nach NEMA  MG1-12',
            'Parte eléctrica según NEMA  MG1-12',
            'Exécution électrique selon NEMA  MG1-12',
            'Designazione materiale elettrico secondo NEMA  MG1-12',
            'Electricamente segundo NEMA  MG1-12',
            'Электрические характеристики согласно NEMA  MG1-12',
            '符合 NEMA  MG1-12 电机标准',
            'NEMA  MG1-12’e uygun elektrikli',
            'Elektrisch volgens NEMA MG1-12',
            'Projekt elektryczny zgodnie z NEMA MG1-12',
            'Elektricky dle NEMA MG1-12'
        ],
        'R10': [
            'Rotation of the terminal box through 90°, entry from DE',
            'Drehen des Anschlusskastens um 90°, Einführung von DE (AS)',
            'Caja de bornes girada 90°, entrada de cables desde LA (DE)',
            'Rotation de la boîte à bornes de 90°, entrée par le côté DE',
            'Rotazione della scatola morsettiera di 90°, ingresso da DE (lato azionamento)',
            'Girar a caixa de ligações em 90°, introdução de DE (AS)',
            'Поворот коробки выводов на 90°, введение со стороны привода (СП)',
            '将接线盒旋转 90°，从驱动侧(DE)引入',
            'Bağlantı kutusunun 90° döndürülmesi, DE’den giriş (AS)',
            'Draaien van de aansluitkast met 90°, invoering van DE (AS)',
            'Obrócenie skrzynki zaciskowej o 90°, przepust kablowy po stronie DE (AS)',
            'Otočení svorkové skříňky o 90°, zavedení kabelu ze strany DE (AS)'
        ],
        'R11': [
            'Terminal box rotated through 90°, cable entry from NDE',
            'Drehen des Anschlusskastens um 90°, Einführung von NDE (BS)',
            'Giro de la caja de bornes 90°, entrada de cables desde NDE (LCA)',
            'Rotation de la boîte à bornes de 90°, entrée par le côté opposé de l’accouplement (NDE)',
            'Rotazione della morsettiera di 90°, ingresso da NDE (BS)',
            'Girar a caixa de ligações em 90°, introdução de NDE (BS)',
            'Поворот коробки выводов на 90°, введение со стороны отбора мощности (СО)',
            '将接线盒旋转 90°，从非驱动侧(NDE)引入',
            'Bağlantı kutusunun 90° döndürülmesi, NDE’den giriş (BS)',
            'Draaien van de aansluitkast met 90°, invoering van NDE (BS)',
            'Obrócenie skrzynki zaciskowej o 90°, przepust kablowy po stronie NDE (BS)',
            'Otočení svorkové skříňky o 90°, zavedení kabelu ze strany NDE (BS)'
        ],
        'R12': [
            'Terminal box rotated through 180°',
            'Drehen des Anschlusskastens um 180°',
            'Caja de bornes girada 180°',
            'Orientation de la boîte à bornes 180°',
            'Cassetta di connessione ruotata di 180°',
            'Caixa de terminais rodada 180°',
            'Поворот коробки выводов на 180°',
            '接线盒旋转180°',
            'Bağlantı kutusunun 180° döndürülmesi',
            'Draaien van de aansluitkast met 180°',
            'Obrócenie skrzynki zaciskowej o 180°',
            'Otočení svorkové skříňky o 180°'
        ],
        'R14': [
            'One EMC cable gland',
            'Eine EMV-Kabelverschraubung',
            'Un pasacables CEM',
            'Un presse-étoupe CEM',
            'Un pressacavo EMC',
            'Uma união roscada de cabo CEM',
            'Резьбовой кабельный разъем ЭМС',
            '一个 EMC 电缆螺钉连接件',
            'EMU’su bulunan kablo vida bağlantısı',
            'Een EMC-kabelschroefverbinding',
            'Jeden dławik kablowy EMC',
            'Kabelová průchodka s osvědčením o EMC'
        ],
        'R15': [
            'One metal cable gland',
            'Eine Kabelverschraubung Metall',
            'Un pasacables en metal',
            'Un presse-étoupe métal',
            'Un pressacavo in metallo',
            'Uma união roscada de cabo em metal',
            'Металлический резьбовой кабельный разъем',
            '一个金属电缆螺钉连接件',
            'Metal kablo vida bağlantısı',
            'Een kabelschroefverbinding metaal',
            'Jeden metalowy dławik kablowy',
            'Kovová kabelová průchodka'
        ],
        'R16': [
            'EMC cable gland, maximum number',
            'EMV-Kabelverschraubung, maximale Bestückung',
            'Pasacables CEM, equipamiento máximo',
            'Presse-étoupe CEM, configuration maximale',
            'Pressacavo EMC, equipaggiamento massimo',
            'União roscada de cabo CEM, equipamento máximo',
            'Резьбовой кабельный разъем ЭМС, максимальное оснащение',
            'EMC 电缆螺钉连接件, 最大装配数量',
            'Kablo vida bağlantısı, azami parça listesi',
            'EMC-kabelschroefverbinding, maximale uitrusting',
            'Dławik kablowy EMC, maksymalna liczba',
            'Kabelová průchodka s osvědčením o EMC, maximální osazení'
        ],
        'R18': [
            'Cable gland metal, max. number',
            'Kabelverschraubung Metall, maximale Bestückung',
            'Pasacables de metal, configuración máxima',
            'Presse-étoupe en métal en équipement maximal',
            'Pressacavi in metallo, equipaggiamento massimo',
            'Parafusos do cabo em metal, configuração máxima',
            'Кабельные вводы металлические, максимальная комплектация',
            '最高配置金属电缆接头',
            'Metalden kablo vidalı bağlantıları, maksimum konfigürasyon',
            'Kabelschroefverbinding metaal, maximale uitrusting',
            'Dławik kablowy z metalu, maksymalna liczba',
            'Kovová kabelová průchodka, maximální osazení'
        ],
        'S00': [
            'No paint finish, only  GG parts primed',
            'Ohne Farbanstrich, nur GG-Teile grundiert',
            'Sin pintar en color, sólo piezas en fundición con imprimación',
            'Sans peinture de finition, seulement pièces GG avec couche de fond',
            'Senza verniciatura, pezzi in ghisa con mano di fondo',
            'Sem demão de tinta, apenas peças na cor primária de ferro fundido',
            'Без красочного покрытия, грунтовка только деталей из серого чугуна',
            '无彩色涂装，仅 GG 部件有底漆',
            'Boya kaplaması yok, sadece GG parçaları astarlanmış',
            'Zonder laklaag, alleen GG-onderdelen van grondverf voorzien',
            'Bez powłoki lakierniczej, tylko zagruntowane części GG',
            'Bez nátěru, základním nátěrem opatřeny pouze díly GG'
        ],
        'S01': [
            'No paint finish, however with primer',
            'Ohne Farbanstrich, jedoch grundiert',
            'Sin pintar, pero con imprimación',
            'Sans peinture mais avec couche de fond',
            'Non verniciato, solo fondo',
            'Sem pintura, mas com cor primária',
            'Без покрытия, только грунтовка',
            '无彩色涂装，但有底漆',
            'Boya kaplaması yok, ancak astarlanmış',
            'Zonder laklaag, maar van grondverf voorzien',
            'Bez powłoki lakierniczej, ale zagruntowane',
            'Bez nátěru, ale opatřeno základním nátěrem'
        ],
        'S02': [
            'Special paint finish C3',
            'Sonderanstrich C3',
            'Pintura especial C3',
            'Peinture spéciale C3',
            'Verniciatura speciale C3',
            'Acabamento de pintura especial C3',
            'Специальное покрытие C3',
            '特殊涂漆C3',
            'Özel boya kaplaması C3',
            'Bijzondere laklaag C3',
            'Malowanie specjalne C3',
            'Speciální nátěr C3'
        ],
        'T99': [
            'Delivery from EU warehouse',
            'Lieferung aus EG-Warenlager',
            'entrega desde almacén en la UE',
            'Livraison depuis entrepôt UE',
            'Fornitura da deposito UE',
            'Delivery from EU warehouse',
            'Доставка со склада ЕС',
            '自欧盟仓库交付',
            'AB mal deposundan teslimat',
            'Levering uit EG-productmagazijn',
            'Dostawa z magazynu WE',
            'Dodávka ze skladu v EU'
        ],
        'D47': [
            'TR CU product safety certificate EAC for the Eurasian Customs Union',
            'TR CU Produktsicherheitszertifikat EAC für die eurasische Zollunion',
            'Certificado de seguridad de producto TR CU EAC para la Unión Aduanera Euroasiática',
            'TR CU certificat de sécurité du produit EAC pour l’union douanière eurasienne',
            'TR CU certificato di sicurezza del prodotto EAC per l’unione doganale eurasiatica',
            'Certificado EAC de segurança do produto TRCU para a União Aduaneira da Eurásia',
            'Сертификат безопасности продукта TR CU EAC для Евразийского Таможенного Союза',
            'TR CU 产品安全证书 EAC，针对欧亚联盟',
            'Avrasya Gümrük Birliği için TR CU Ürün Güvenliği Sertifikası EAC',
            'TR CU productveiligheidscertificaat EAC voor de Eurazische tolunie',
            'TR CU Certyfikat zgodności EAC dla Euroazjatyckiej Unii Celnej',
            'TR CU Certifikát o bezpečnosti produktu EAC pro euroasijskou celní unii'
        ],
        'B02': [
            'Acceptance test certificate 3.1 acc. to EN 10204',
            'Abnahmeprüfzeugnis 3.1 nach EN 10204',
            'Certificado de ensayo (protocolo de pruebas) 3.1 según EN 10204',
            'Certificat de réception 3.1 selon 10204',
            'Certificato di collaudo 3.1 secondo EN 10204',
            'Certificado de teste de aceitação 3.1 de acordo com a norma EN 10204',
            'Сертификат приемочного испытания 3.1 согласно EN 10204',
            '验收测试证明 3.1，符合 EN 10204',
            'EN 10204’e uygun kabul kontrol sertifikası 3.1',
            'Proefresultaat oplevering 3.1 volgens EN 10204',
            'Świadectwo odbioru 3.1 zgodnie z normą EN 10204',
            'Osvědčení o přejímací zkoušce 3.1 podle EN 10204'
        ],
        'B07': [
            'Additional rating plate for voltage tolerance',
            'Zusatzschild Spannungstoleranz',
            'Placa adicional tolerancia de tensión',
            'Plaque supplémentaire tolérance de tension',
            'Targhetta aggiuntiva tolleranza di tensione',
            'Chapa adicional de tolerância de tensão',
            'Дополнительная табличка с указанием допуска на колебание напряжения',
            '电压公差的附加铭牌',
            'Gerilim toleransı ek etiketi',
            'Additional rating plate for voltage tolerance',
            'Dodatkowa tabliczka znamionowa dla tolerancji napięcia',
            'Dodatečný štítek s rozsahy napětí'
        ],
        'B13': [
            'Without \'Made in manufacturing country\' marking',
            'Ohne Kennzeichnung „Made in Herkunftsland”',
            'Sin marcado \'Made in …\' en la placa de características  ni en la etiqueta del embalaje',
            'Sans marquage \'Made in …\' sur la plaque signalétique  et l\'étiquette d\'emballage',
            'Senza contrassegno \'Made in …\' sulla targhetta dei dati tecnici  e sull\'etichetta dell\'imballo',
            'Sem marcação \'Made in …\' na chapa indicadora de potência  e etiqueta na embalagem',
            'Без маркировки «Made in …» на табличке с паспортными данными  и упаковочной этикетке',
            '功率铭牌和包装标签上  没有“制造国”标记',
            'Güç levhası ve ambalaj etiketi üzerinde \'Made in …\'  işareti olmadan',
            'Without \'Made in manufacturing country\' marking',
            'Bez oznaczenia „Wyprodukowano w kraju pochodzenia”',
            'Bez označení \'Vyrobeno v zemi původu\''
        ],
        'D22': [
            'Motor without CE character for export outside the EEA (see EU regulation 640/2009)',
            'Motor ohne CE-Zeichen für Export außerhalb EWR (siehe EU-Verordnung 640/2009)',
            'Motor sin marca CE para la exportación fuera del EEE (ver el Reglamento de la UE 640/2009)',
            'Moteur sans marquage CE pour exportation hors de l’EEE (voir règlement CE 640/2009)',
            'Motore senza marchio CE per esportazione al di fuori del SEE (v. decreto CE 640/2009)',
            'Motor sem marca CE para exportação fora do EEE (ver regulamento UE 640/2009)',
            'Двигатель без CE-символа для экспорта за пределы Европейского экономического пространства (см. Постановление ЕС 640/2009)',
            '无 CE 标志的 电机，用于欧洲经济区以外地区的出口（参见欧盟标准 640/2009）',
            'EEA dışına ihracat için CE işareti olmadan motor (bkz. AB Yönetmeliği 640/2009)',
            'Motor zonder CE-markering voor export buiten EER (zie EG-verordening 640/2009)',
            'Silnik bez znaku CE przeznaczony na eksport poza EOG (patrz rozporządzenie UE 640/2009)',
            'Motor bez označení CE pro vývoz mimo EHP (viz nařízení EU 640/2009)'
        ],
        'F01': [
            'Mounting of holding brake (standard assignment)',
            'Anbau Haltebremse (Standardzuordnung)',
            'Mounting of holding brake (standard assignment)',
            'Mounting of holding brake (standard assignment)',
            'Mounting of holding brake (standard assignment)',
            'Mounting of holding brake (standard assignment)',
            'Mounting of holding brake (standard assignment)',
            'Mounting of holding brake (standard assignment)',
            'Mounting of holding brake (standard assignment)',
            'Mounting of holding brake (standard assignment)',
            'Mounting of holding brake (standard assignment)',
            'Mounting of holding brake (standard assignment)'
        ],
        'F10': [
            'Brake supply voltage 24 V DC',
            'Bremsenanschlussspannung DC 24 V',
            'Brake supply voltage 24 V DC',
            'Brake supply voltage 24 V DC',
            'Brake supply voltage 24 V DC',
            'Brake supply voltage 24 V DC',
            'Brake supply voltage 24 V DC',
            'Brake supply voltage 24 V DC',
            'Brake supply voltage 24 V DC',
            'Brake supply voltage 24 V DC',
            'Brake supply voltage 24 V DC',
            'Brake supply voltage 24 V DC'
        ],
        'F11': [
            'Brake supply voltage 230 V AC, 50/60 Hz',
            'Bremsenanschlussspannung AC 230 V, 50/60 Hz',
            'Brake supply voltage 230 V AC, 50/60 Hz',
            'Brake supply voltage 230 V AC, 50/60 Hz',
            'Brake supply voltage 230 V AC, 50/60 Hz',
            'Brake supply voltage 230 V AC, 50/60 Hz',
            'Brake supply voltage 230 V AC, 50/60 Hz',
            'Brake supply voltage 230 V AC, 50/60 Hz',
            'Brake supply voltage 230 V AC, 50/60 Hz',
            'Brake supply voltage 230 V AC, 50/60 Hz',
            'Brake supply voltage 230 V AC, 50/60 Hz',
            'Brake supply voltage 230 V AC, 50/60 Hz'
        ],
        'F12': [
            'Brake supply voltage 400 V AC, 50/60 Hz',
            'Bremsenanschlussspannung AC 400 V, 50/60 Hz',
            'Brake supply voltage 400 V AC, 50/60 Hz',
            'Brake supply voltage 400 V AC, 50/60 Hz',
            'Brake supply voltage 400 V AC, 50/60 Hz',
            'Brake supply voltage 400 V AC, 50/60 Hz',
            'Brake supply voltage 400 V AC, 50/60 Hz',
            'Brake supply voltage 400 V AC, 50/60 Hz',
            'Brake supply voltage 400 V AC, 50/60 Hz',
            'Brake supply voltage 400 V AC, 50/60 Hz',
            'Brake supply voltage 400 V AC, 50/60 Hz',
            'Brake supply voltage 400 V AC, 50/60 Hz'
        ],
        'F70': [
            'IC416 - Mounting of separately driven fan',
            'IC416 - Fremdbelüftet Oberflächengekühlt',
            'IC416 - Mounting of separately driven fan',
            'IC416 - Mounting of separately driven fan',
            'IC416 - Mounting of separately driven fan',
            'IC416 - Mounting of separately driven fan',
            'IC416 - Mounting of separately driven fan',
            'IC416 - Mounting of separately driven fan',
            'IC416 - Mounting of separately driven fan',
            'IC416 - Mounting of separately driven fan',
            'IC416 - Mounting of separately driven fan',
            'IC416 - Mounting of separately driven fan'
        ],
        'F74': [
            'Sheet steel fan cowl',
            'Blechlüfterhaube',
            'Capota de ventilador de chapa',
            'Capot de ventilateur en tôle',
            'Calotta copriventola in lamiera',
            'Tampa do ventilador em chapa',
            'Штампованный кожух вентилятора',
            '板式风扇外罩',
            'Sactan mamul fan davlumbazı',
            'Plaatventilatorkap',
            'Osłona wentylatora z blachy stalowej',
            'Plechový kryt ventilátoru'
        ],
        'F76': [
            'Metal external fan',
            'Metall-Außenlüfter',
            'Ventilador externo metálico',
            'Ventilateur externe métal',
            'Ventilatore esterno in metallo',
            'Ventilador exterior em metal',
            'Металлический наружный вентилятор',
            '金属外部风扇',
            'Harici metal fan',
            'Metalen externe ventilator',
            'Metalowy wentylator zewnętrzny',
            'Vnější kovový ventilátor'
        ],
        'F90': [
            'IC418 - without fan and without housing fan',
            'IC418 - Ohne Außenlüfter und ohne Lüfterhaube',
            'IC418 - without fan and without housing fan',
            'IC418 - without fan and without housing fan',
            'IC418 - without fan and without housing fan',
            'IC418 - without fan and without housing fan',
            'IC418 - without fan and without housing fan',
            'IC418 - without fan and without housing fan',
            'IC418 - without fan and without housing fan',
            'IC418 - without fan and without housing fan',
            'IC418 - without fan and without housing fan',
            'IC418 - without fan and without housing fan'
        ],
        'G04': [
            'LL 861 900 220',
            'LL 861 900 220',
            'LL 861 900 220',
            'LL 861 900 220',
            'LL 861 900 220',
            'LL 861 900 220',
            'LL 861 900 220',
            'LL 861 900 220',
            'LL 861 900 220',
            'LL 861 900 220',
            'LL 861 900 220',
            'LL 861 900 220'
        ],
        'G11': [
            'Sendix 5020 (HTL)',
            'Sendix 5020 (HTL)',
            'Sendix 5020 (HTL)',
            'Sendix 5020 (HTL)',
            'Sendix 5020 (HTL)',
            'Sendix 5020 (HTL)',
            'Sendix 5020 (HTL)',
            'Sendix 5020 (HTL)',
            'Sendix 5020 (HTL)',
            'Sendix 5020 (HTL)',
            'Sendix 5020 (HTL)',
            'Sendix 5020 (HTL)'
        ],
        'G12': [
            'Sendix 5020 (TTL)',
            'Sendix 5020 (TTL)',
            'Sendix 5020 (TTL)',
            'Sendix 5020 (TTL)',
            'Sendix 5020 (TTL)',
            'Sendix 5020 (TTL)',
            'Sendix 5020 (TTL)',
            'Sendix 5020 (TTL)',
            'Sendix 5020 (TTL)',
            'Sendix 5020 (TTL)',
            'Sendix 5020 (TTL)',
            'Sendix 5020 (TTL)'
        ],
        'G40': [
            'Prepared for mounted components, centering hole only',
            'Prepared for mounted components, centering hole only',
            'Prepared for mounted components, centering hole only',
            'Prepared for mounted components, centering hole only',
            'Prepared for mounted components, centering hole only',
            'Prepared for mounted components, centering hole only',
            'Prepared for mounted components, centering hole only',
            'Prepared for mounted components, centering hole only',
            'Prepared for mounted components, centering hole only',
            'Prepared for mounted components, centering hole only',
            'Prepared for mounted components, centering hole only',
            'Prepared for mounted components, centering hole only'
        ],
        'G46': [
            'Prepared for mountings, threaded hole only',
            'Vorbereitet für Anbauten, nur Gewindebohrung',
            'Preparado para soportes, solo orificio roscado',
            'Préparé pour montages, trou fileté uniquement',
            'Predisposto per supporti di montaggio, solo foro filettato',
            'Prepared for mountings, threaded hole only',
            'Подготовка для навесного оборудования, только резьбовое отверстие',
            '准备安装， 仅限螺纹孔',
            'Montaj için hazırlanmış, sadece dişli delik',
            'Prepared for mountings, threaded hole only',
            'Przygotowany do montażu dodatkowych komponentów, tylko otwór gwintowany',
            'Připraveno pro nástavby, pouze díry se závitem'
        ],
        'H00': [
            'Canopy',
            'Schutzdach',
            'Cubierta protectora',
            'Capot de protection',
            'Tettuccio di protezione',
            'Telhado de protecção',
            'Защитная крышка',
            '防护盖',
            'Koruyucu çatı',
            'Beschermdak',
            'Daszek ochronny',
            'Ochranná stříška'
        ],
        'H01': [
            'Bolted on mounting feet (instead of cast)',
            'Füße angeschraubt (statt angegossen)',
            'Bolted on mounting feet (instead of cast)',
            'Bolted on mounting feet (instead of cast)',
            'Bolted on mounting feet (instead of cast)',
            'Bolted on mounting feet (instead of cast)',
            'Bolted on mounting feet (instead of cast)',
            'Bolted on mounting feet (instead of cast)',
            'Bolted on mounting feet (instead of cast)',
            'Bolted on mounting feet (instead of cast)',
            'Bolted on mounting feet (instead of cast)',
            'Bolted on mounting feet (instead of cast)'
        ],
        'H03': [
            'Condensation drain holes in end shield',
            'Kondenswasserlöcher in Lagerschilden',
            'Orificios de drenaje para condensación de agua',
            'Trous d’évacuation de l’eau de condensation',
            'Fori per scarico condensa',
            'Furos de água de condensação',
            'Отверстия для стока конденсата',
            '冷凝水孔',
            'Yoğuşma suyu delikleri',
            'Condenswateropeningen in lagerplaatjes',
            'Otwory spustu kondensatu na tarczach łożyskowych',
            'Otvory pro odvádění kondenzované vody v ložiskových štítech'
        ],
        'H04': [
            'External grounding at housing',
            'Äußere Erdung am Gehäuse',
            'Toma de tierra externa',
            'Mise à la terre externe',
            'Messa a terra esterna',
            'Ligação à terra externa',
            'Внешнее заземление',
            '外部接地',
            'Dış toprak hattı',
            'Buitenste aarding aan de behuizing',
            'Zewnętrzne uziemienie na obudowie',
            'Vnější uzemnění na skříni'
        ],
        'H20': [
            'IP 65 degree of protection',
            'Schutzart IP 65',
            'Grado de protección IP 65',
            'Degré de protection IP 65',
            'Grado di protezione IP 65',
            'Grau de protecção IP 65',
            'Степень защиты IP 65',
            '防护等级 IP 65',
            'Koruma türü IP 65',
            'Beschermingsgraad IP 65',
            'Stopień ochrony IP 65',
            'Krytí IP 65'
        ],
        'H22': [
            'IP 56 degree of protection',
            'Schutzart IP 56',
            'Grado de protección IP 56',
            'Degré de protection IP 56',
            'Grado di protezione IP 56',
            'Grau de protecção IP 56',
            'Степень защиты IP 56',
            '防护等级 IP 56',
            'Koruma türü IP 56',
            'Beschermingsgraad IP 56',
            'Stopień ochrony IP 56',
            'Krytí IP 56'
        ],
        'L20': [
            'Locating bearing, DE',
            'Festlager DE (AS)',
            'Rodamiento fijo LA',
            'Palier fixe côté D',
            'Cuscinetto fisso DE (AS)',
            'Mancal fixo DE (AS)',
            'Фиксированный подшипник, сторона привода (AS)',
            '固定轴承（驱动侧）',
            'Sabit yatak DE (AS)',
            'Vast lager DE (AS)',
            'Łożysko ustalające DE (AS)',
            'Pevné ložisko DE (AS)'
        ],
        'L22': [
            'Bearing design for increased cantilever forces',
            'Lagerung für erhöhte Querkräfte',
            'Rodamientos reforzados para cargas radiales elevadas',
            'Paliers pour forces transversales augmentées',
            'Cuscinetti per forze radiali elevate',
            'Mancal para forças transversais mais elevadas',
            'Опора для повышенных поперечных нагрузок',
            '用于提升横向力的轴承',
            'Yükseltilmiş enine kuvvetler için yataklama',
            'Lagering voor verhoogde dwarskrachten',
            'Łożyskowanie dla zwiększonych sił poprzecznych',
            'Uložení pro zvýšené příčné síly'
        ],
        'L23': [
            'Regreasing system',
            'Nachschmiereinrichtung',
            'Dispositivo de reengrase',
            'Dispositif de regraissage',
            'Ingrassatore',
            'Dispositivo de relubrificação',
            'Устройство дополнительной смазки',
            '润滑补充装置',
            'Ardıl yağlama düzeneği',
            'Nasmeerinrichting',
            'Urządzenie dosmarowujące',
            'Domazávací zařízení'
        ],
        'L25': [
            'Special bearings for DE and NDE, bearing size 63',
            'Sonderlager für DE (AS) und NDE (BS), Lagergröße 63',
            'Special bearings for DE and NDE, bearing size 63',
            'Special bearings for DE and NDE, bearing size 63',
            'Special bearings for DE and NDE, bearing size 63',
            'Special bearings for DE and NDE, bearing size 63',
            'Special bearings for DE and NDE, bearing size 63',
            'Special bearings for DE and NDE, bearing size 63',
            'Special bearings for DE and NDE, bearing size 63',
            'Special bearings for DE and NDE, bearing size 63',
            'Special bearings for DE and NDE, bearing size 63',
            'Special bearings for DE and NDE, bearing size 63'
        ],
        'L51': [
            'Bearing insulation NDE',
            'Lagerisolierung NDE (BS)',
            'Aislamiento de los cojinetes LCA',
            'Isolation de palier côté N',
            'Isolamento cuscinetti NDE (BS)',
            'Isolamento do mancal NDE (BS)',
            'Изоляция подшипника на стороне отбора мощности (BS)',
            '轴承绝缘（非驱动侧）',
            'Yatak izolasyonu NDE (BS)',
            'Lagerisolering NDE (BS)',
            'Izolacja łożyska NDE (BS)',
            'Izolace ložiska NDE (BS)'
        ],
        'M11': [
            'Stainless steel rating plate',
            'Leistungsschild aus nichtrostendem Stahl',
            'Placa de características en acero inoxidable',
            'Plaque signalétique en acier Inox',
            'Targhetta dei dati tecnici, acciaio inossidabile',
            'Placa de características de aço inoxidável',
            'Табличка с паспортными данными из нержавеющей стали',
            '不锈钢功率铭牌',
            'Paslanmaz çelikten güç etiketi',
            'Vermogensplaatje uit niet roestend staal',
            'Tabliczka znamionowa ze stali nierdzewnej',
            'Nerezový typový štítek'
        ],
        'Q01': [
            'Measuring nipple for SPM shock pulse measurement to monitor bearings',
            'Messnippel für SPM-Stoßimpulsmessung für Lagerkontrolle',
            'Measuring nipple for SPM shock pulse measurement to monitor bearings',
            'Measuring nipple for SPM shock pulse measurement to monitor bearings',
            'Measuring nipple for SPM shock pulse measurement to monitor bearings',
            'Measuring nipple for SPM shock pulse measurement to monitor bearings',
            'Measuring nipple for SPM shock pulse measurement to monitor bearings',
            'Measuring nipple for SPM shock pulse measurement to monitor bearings',
            'Measuring nipple for SPM shock pulse measurement to monitor bearings',
            'Measuring nipple for SPM shock pulse measurement to monitor bearings',
            'Measuring nipple for SPM shock pulse measurement to monitor bearings',
            'Measuring nipple for SPM shock pulse measurement to monitor bearings'
        ],
        'Q02': [
            'Anti-condensation heating for 230 V (2 terminals)',
            'Stillstandsheizung für 230 V (2 Klemmen)',
            'Calefección anticondensaciones para 230 V (2 bornes)',
            'Chauffage à l\'arrêt pour 230 V (2 bornes)',
            'Dispositivo di riscaldamento anticondensa per 230 V (2 morsetti)',
            'Aquecedor estacionário para 230 V (2 terminais)',
            'Противоконденсатный обогреватель на 230 В (2 клеммы)',
            '230 V 防冷凝加热器（2 个端子）',
            '230 V için bağımsız ısıtma (2 terminal)',
            'Stilstandverwarming voor 230 V (2 klemmen)',
            'Ogrzewanie postojowe dla 230 V (2 zaciski)',
            'Vytápění zastaveného motoru na 230 V (2 svorky)'
        ],
        'Q03': [
            'Anti-condensation heating 115 V',
            'Stillstandsheizung 115 V',
            'Calefacción anticondensaciones 115 V',
            'Chauffage à l’arrêt 115 V',
            'Scaldiglie anticondensa 115 V',
            'Aquecimento de imobilização 115 V',
            'Противоконденсатный обогреватель 115 В',
            '防冷凝加热器 115 V',
            'Bağımsız ısıtma sistemi 115V',
            'Stilstandverwarming 115 V',
            'Ogrzewanie postojowe 115 V',
            'Vytápění zastaveného motoru 115 V'
        ],
        'Q60': [
            '3 PT100 resistance thermometers in stator winding - 2-wire (6 terminals)',
            '3 Widerstandsthermometer PT100 - 2-Leiterschaltung (6 Klemmen)',
            '3 PT100 resistance thermometers in stator winding - 2-wire (6 terminals)',
            '3 PT100 resistance thermometers in stator winding - 2-wire (6 terminals)',
            '3 PT100 resistance thermometers in stator winding - 2-wire (6 terminals)',
            '3 PT100 resistance thermometers in stator winding - 2-wire (6 terminals)',
            '3 PT100 resistance thermometers in stator winding - 2-wire (6 terminals)',
            '3 PT100 resistance thermometers in stator winding - 2-wire (6 terminals)',
            '3 PT100 resistance thermometers in stator winding - 2-wire (6 terminals)',
            '3 PT100 resistance thermometers in stator winding - 2-wire (6 terminals)',
            '3 PT100 resistance thermometers in stator winding - 2-wire (6 terminals)',
            '3 PT100 resistance thermometers in stator winding - 2-wire (6 terminals)'
        ],
        'Q61': [
            '6 PT100 resistance thermometers in stator winding - 2-wire (12 terminals)',
            '6 Widerstandsthermometer PT100 - 2-Leiterschaltung (12 Klemmen)',
            '6 PT100 resistance thermometers in stator winding - 2-wire (12 terminals)',
            '6 PT100 resistance thermometers in stator winding - 2-wire (12 terminals)',
            '6 PT100 resistance thermometers in stator winding - 2-wire (12 terminals)',
            '6 PT100 resistance thermometers in stator winding - 2-wire (12 terminals)',
            '6 PT100 resistance thermometers in stator winding - 2-wire (12 terminals)',
            '6 PT100 resistance thermometers in stator winding - 2-wire (12 terminals)',
            '6 PT100 resistance thermometers in stator winding - 2-wire (12 terminals)',
            '6 PT100 resistance thermometers in stator winding - 2-wire (12 terminals)',
            '6 PT100 resistance thermometers in stator winding - 2-wire (12 terminals)',
            '6 PT100 resistance thermometers in stator winding - 2-wire (12 terminals)'
        ],
        'Q63': [
            '3 PT100 resistance thermometers in stator winding - 3-wire (9 terminals)',
            '3 Widerstandsthermometer PT100 - 3-Leiterschaltung (9 Klemmen)',
            '3 PT100 resistance thermometers in stator winding - 3-wire (9 terminals)',
            '3 PT100 resistance thermometers in stator winding - 3-wire (9 terminals)',
            '3 PT100 resistance thermometers in stator winding - 3-wire (9 terminals)',
            '3 PT100 resistance thermometers in stator winding - 3-wire (9 terminals)',
            '3 PT100 resistance thermometers in stator winding - 3-wire (9 terminals)',
            '3 PT100 resistance thermometers in stator winding - 3-wire (9 terminals)',
            '3 PT100 resistance thermometers in stator winding - 3-wire (9 terminals)',
            '3 PT100 resistance thermometers in stator winding - 3-wire (9 terminals)',
            '3 PT100 resistance thermometers in stator winding - 3-wire (9 terminals)',
            '3 PT100 resistance thermometers in stator winding - 3-wire (9 terminals)'
        ],
        'Q72': [
            '2 PT100 screw-in resistance thermometers in a basic connection for bearings (2 terminals)',
            '2 Einschraub-Thermometer PT100 in Grundschaltung für Lager (2 Klemmen)',
            '2 PT100 screw-in resistance thermometers in a basic connection for bearings (2 terminals)',
            '2 PT100 screw-in resistance thermometers in a basic connection for bearings (2 terminals)',
            '2 PT100 screw-in resistance thermometers in a basic connection for bearings (2 terminals)',
            '2 PT100 screw-in resistance thermometers in a basic connection for bearings (2 terminals)',
            '2 PT100 screw-in resistance thermometers in a basic connection for bearings (2 terminals)',
            '2 PT100 screw-in resistance thermometers in a basic connection for bearings (2 terminals)',
            '2 PT100 screw-in resistance thermometers in a basic connection for bearings (2 terminals)',
            '2 PT100 screw-in resistance thermometers in a basic connection for bearings (2 terminals)',
            '2 PT100 screw-in resistance thermometers in a basic connection for bearings (2 terminals)',
            '2 PT100 screw-in resistance thermometers in a basic connection for bearings (2 terminals)'
        ],
        'Q78': [
            '2 PT100 screw-in resistance thermometers (3-wire) for bearings (6 terminals)',
            '2 Einschraub-Thermometer PT100 in 3-Leiterschaltung für Lager (6 Klemmen)',
            '2 PT100 screw-in resistance thermometers (3-wire) for bearings (6 terminals)',
            '2 PT100 screw-in resistance thermometers (3-wire) for bearings (6 terminals)',
            '2 PT100 screw-in resistance thermometers (3-wire) for bearings (6 terminals)',
            '2 PT100 screw-in resistance thermometers (3-wire) for bearings (6 terminals)',
            '2 PT100 screw-in resistance thermometers (3-wire) for bearings (6 terminals)',
            '2 PT100 screw-in resistance thermometers (3-wire) for bearings (6 terminals)',
            '2 PT100 screw-in resistance thermometers (3-wire) for bearings (6 terminals)',
            '2 PT100 screw-in resistance thermometers (3-wire) for bearings (6 terminals)',
            '2 PT100 screw-in resistance thermometers (3-wire) for bearings (6 terminals)',
            '2 PT100 screw-in resistance thermometers (3-wire) for bearings (6 terminals)'
        ],
        'R50': [
            'Next largest terminal box',
            'Nächst größerer Anschlusskasten',
            'Next largest terminal box',
            'Next largest terminal box',
            'Next largest terminal box',
            'Next largest terminal box',
            'Next largest terminal box',
            'Next largest terminal box',
            'Next largest terminal box',
            'Next largest terminal box',
            'Next largest terminal box',
            'Next largest terminal box'
        ],
        'R62': [
            'Cast iron auxiliary terminal box (small)',
            'Hilfsanschlusskasten Grauguss (klein)',
            'Caja de bornes auxiliar fundición gris (pequeña)',
            'Boîte à bornes auxiliaire en fonte grise (petite)',
            'Morsettiera ausiliaria in ghisa (piccola)',
            'Caixa de bornes auxiliar em ferro fundido (pequena)',
            'Вспомогательная клеммная коробка, серый чугун (малая)',
            '灰铸铁辅助接线盒（小）',
            'Gri döküm yardımcı bağlantı kutusu (küçük)',
            'Hulpaansluitkast gietijzer (klein)',
            'Pomocnicza skrzynka zaciskowa z żeliwa (mała)',
            'Pomocná svorková skříňka, šedá litina (malá)'
        ],
        'S03': [
            'Special paint finish in sea air resistant C4',
            'Sonderanstrich seeluftfest C4',
            'Pintura especial resistente a atmósfera marina C4',
            'Peinture spéciale résistante à l’atmosphère marine C4',
            'Verniciatura speciale resistente alla salsedine C4',
            'Pintura especial resistente à maresia C4',
            'Специальное покрытие, стойкое к морскому воздуху C4',
            '特殊涂装（耐海洋空气）C4',
            'Deniz havasına dayanıklı özel boya kaplaması C4',
            'Bijzondere laklaag, zeeluchtvast C4',
            'Malowanie specjalne odporne na powietrze morskie C4',
            'Speciální nátěr s odolností vůči mořskému vzduchu C4'
        ],
        'Y53': [
            'Standard RAL-Farbton',
            'Standard RAL-Farbton',
            'Standard RAL-Farbton',
            'Standard RAL-Farbton',
            'Standard RAL-Farbton',
            'Standard RAL-Farbton',
            'Standard RAL-Farbton',
            'Standard RAL-Farbton',
            'Standard RAL-Farbton',
            'Standard RAL-Farbton',
            'Standard RAL-Farbton',
            'Standard RAL-Farbton'
        ],
        'Y56': [
            'Sonder RAL-Farbton',
            'Sonder RAL-Farbton',
            'Sonder RAL-Farbton',
            'Sonder RAL-Farbton',
            'Sonder RAL-Farbton',
            'Sonder RAL-Farbton',
            'Sonder RAL-Farbton',
            'Sonder RAL-Farbton',
            'Sonder RAL-Farbton',
            'Sonder RAL-Farbton',
            'Sonder RAL-Farbton',
            'Sonder RAL-Farbton'
        ],
        'Y82': [
            'Supplementary plate with order data',
            'Zusatzschild mit Bestellangaben',
            'Supplementary plate with order data',
            'Supplementary plate with order data',
            'Supplementary plate with order data',
            'Supplementary plate with order data',
            'Supplementary plate with order data',
            'Supplementary plate with order data',
            'Supplementary plate with order data',
            'Supplementary plate with order data',
            'Supplementary plate with order data',
            'Supplementary plate with order data'
        ],
        'Y84': [
            'Additional data on the rating plate and on the packaging label',
            'Zusatzangaben auf Leistungsschild und auf Verpackungsetikett',
            'Additional data on the rating plate and on the packaging label',
            'Additional data on the rating plate and on the packaging label',
            'Additional data on the rating plate and on the packaging label',
            'Additional data on the rating plate and on the packaging label',
            'Additional data on the rating plate and on the packaging label',
            'Additional data on the rating plate and on the packaging label',
            'Additional data on the rating plate and on the packaging label',
            'Additional data on the rating plate and on the packaging label',
            'Additional data on the rating plate and on the packaging label',
            'Additional data on the rating plate and on the packaging label'
        ],
        'D99': [
            'Product Branding Innomotics with deliveries starting 01.08.2024',
            'Produktmarke Innomotics mit Lieferungen ab 01.08.2024',
            'Product Branding Innomotics with deliveries starting 01.08.2024',
            'Product Branding Innomotics with deliveries starting 01.08.2024',
            'Product Branding Innomotics with deliveries starting 01.08.2024',
            'Product Branding Innomotics with deliveries starting 01.08.2024',
            'Product Branding Innomotics with deliveries starting 01.08.2024',
            'Product Branding Innomotics with deliveries starting 01.08.2024',
            'Product Branding Innomotics with deliveries starting 01.08.2024',
            'Product Branding Innomotics with deliveries starting 01.08.2024',
            'Product Branding Innomotics with deliveries starting 01.08.2024',
            'Product Branding Innomotics with deliveries starting 01.08.2024'
        ]
    },
    'optionGroup': {
        'bearings': [
            'Bearings',
            'Lagerung',
            'Roulements',
            'Roulements',
            'Roulements',
            'Roulements',
            'Подшипники',
            '轴承',
            'yatak',
            'Lagers',
            'Łożyska',
            'ložiska'
        ],
        'certificates': [
            'Certificates',
            'Zertifikate',
            'Certificats',
            'Certificats',
            'Certificats',
            'Certificats',
            'Сертификаты',
            '证书',
            'sertifikalar',
            'Certificaten',
            'Certyfikaty',
            'certifikáty'
        ],
        'documentation': [
            'Documentation',
            'Dokumentation',
            'Documentation',
            'Documentation',
            'Documentation',
            'Documentation',
            'Документация',
            '文件资料',
            'belgeleme',
            'Documentatie',
            'Dokumentacja',
            'Dokumentace'
        ],
        'mechanicalVersion': [
            'Mechanical version',
            'Mechanische Ausführungen',
            'version mécanique',
            'version mécanique',
            'version mécanique',
            'version mécanique',
            'механическая версия',
            '机械设计',
            'mekanik versiyon',
            'mechanische versie',
            'wersja mechaniczna',
            'mechanické provedení'
        ],
        'painting': [
            'Painting',
            'Farbanstrich',
            'Pintura',
            'La peinture',
            'Pittura',
            'Pintura',
            'Картина',
            '涂料',
            'Boyama',
            'Coating',
            'Malowanie',
            'Nátěr'
        ]
    }
}
);
