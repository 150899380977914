export enum IconType {
    AcknowledgeEvents,
    AddWorkorder,
    Apps,
    ArrowDoubleLeft,
    ArrowDoubleRight,
    Arrowdown,
    Arrowheaddown,
    Arrowheadleft,
    Arrowheadright,
    Arrowheadup,
    Arrowleft,
    Arrowright,
    Arrowup,
    Attach,
    Back,
    BarChart,
    Bookmark,
    BrowseAll,
    Buildings,
    Calendar,
    Chat,
    Check,
    CheckSmall,
    Close,
    CloseBig,
    CloseSmall,
    Configuration,
    Configure,
    Copy,
    Cut,
    Delivery,
    Document,
    DocumentDOC,
    DocumentMP4,
    DocumentPDF,
    DocumentPPT,
    DocumentSuccess,
    DocumentTXT,
    DocumentXLS,
    Download,
    Dropdown,
    Edit,
    Email,
    Error,
    Explore,
    Facebook,
    Favorite,
    Filter,
    Folder,
    FolderNew,
    FolderOpen,
    FullScreen,
    FullScreenExit,
    GroupPeople,
    Hide,
    History,
    Home,
    HomeSmall,
    HorizontalView,
    Hourglass,
    Image,
    Information,
    Instagram,
    InteractionClick,
    InteractionTouch,
    Language,
    LineChart,
    Link,
    Linkedin,
    Linkextern,
    Location,
    Locked,
    Login,
    Logout,
    Maximize,
    Menu,
    Microphone,
    Minimize,
    Minus,
    Monitor,
    NavigationLeft,
    NavigationRight,
    Notification,
    Optimize,
    Options,
    OptionsHorizontal,
    Paste,
    Pause,
    Phone,
    Pin,
    Pinned,
    Plant,
    Play,
    Plus,
    PlusSmall,
    QRCode,
    Question,
    Readingtime,
    Redo,
    Refresh,
    Reset,
    Resize,
    Save,
    Search,
    SearchSmall,
    Settings,
    Share,
    Shoppingcart,
    Show,
    Skip,
    SkipBack,
    Sort,
    SortAscending,
    SortDescending,
    SortDown,
    SortUp,
    SortUpDown,
    Stop,
    Success,
    Support,
    Trash,
    Twitter,
    Undo,
    Unlocked,
    User,
    VerticalView,
    VolumeLoud,
    VolumeMute,
    VolumeQuiet,
    Warning,
    Weight,
    Yammer,
    YouTube,
    ZoomIn,
    ZoomOut
}
