import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    CALLER,
    CONFIGID,
    COUNTRY,
    HOOK_URL,
    KEEP_ALIVE_URL, LANGUAGE, REGION, RESULT_URL,
    SCENARIO, TIA_SCENARIO, TIA_SCENARIO_CONFIG_ID,
    TIA_SCENARIO_RESULT_FORMAT, TIA_SCENARIO_UI_MODE,
    TRANSLATIONS
} from '../../constants';
import { HeaderTabType } from '../../enums';
import { useAppSelector, useTranslate } from '../../hooks/common';
import { AvailableValues, CommercialDataRequest, Filter, FilterValue, OptionValidationRequest, PrimaryFilter, SecondaryFilter } from '../../models';
import { Api, apiCall, getCommercialDataRequest, getOptionValidationRequest, getTrackData, mergeOptionData, trackEvent } from '../../services';
import {
    addSnackbar,
    clearHiddenIds, clearNewIds,
    setActiveTabType,
    setAvailableValues,
    setCommercialData,
    setDtkData,
    setFilter,
    setInitialAvailableValues, setInitialSecondaryFilter,
    setMotors, setOptionData,
    setSpcData,
    toggleLoading, toggleLoadingDeliveryTimes, toggleLoadingOptions
} from '../../store';
import { SnackbarType } from '../common/Snackbar';
import Selector from './Selector';

const SelectorManager = () => {
    const dispatch = useDispatch();
    const optionData = useAppSelector(state => state.selector.optionData);
    const urlSearchParameters = new URLSearchParams(useLocation().search);
    const translate = useTranslate();

    useEffect(() => {
        (async () => {
            dispatch(toggleLoading());
            window.scrollTo(0, 0);
            dispatch(setActiveTabType(HeaderTabType.Selector));
            const [caller, scenario] = [CALLER, SCENARIO].map(x => urlSearchParameters.get(x));

            if (scenario && scenario === TIA_SCENARIO) {
                const [hookUrl, region, language] = [HOOK_URL, COUNTRY, LANGUAGE].map(x => urlSearchParameters.get(x));
                dispatch(setSpcData({
                    scenario: scenario,
                    caller: caller ?? undefined,
                    hookUrl: hookUrl ?? undefined,
                    resultformat: TIA_SCENARIO_RESULT_FORMAT,
                    uimode: TIA_SCENARIO_UI_MODE,
                    configId: TIA_SCENARIO_CONFIG_ID,
                    region: region ?? undefined,
                    language: language?.toLocaleUpperCase() ?? undefined
                }));
            } else {
                const [keepAliveUrl, resultUrl, configId, region, language] = [KEEP_ALIVE_URL, RESULT_URL, CONFIGID, REGION, LANGUAGE].map(x => urlSearchParameters.get(x));
                keepAliveUrl && resultUrl && dispatch(setDtkData({ keepAliveUrl, resultUrl }));
                configId && dispatch(setSpcData({ configId, region: region ?? undefined, language: language ?? undefined }));
            }

            await apiCall(
                Api.getInitialValues(),
                async x => {
                    dispatch(setAvailableValues(x.data));
                    dispatch(setInitialAvailableValues(x.data));
                },
                async () => {
                    dispatch(addSnackbar({
                        text: translate(TRANSLATIONS.error.availableValues), type: SnackbarType.Error
                    }));
                }
            );

            dispatch(toggleLoading());
        })();
    }, []);

    const track = (name: string, properties: Record<string, unknown>) => trackEvent(getTrackData(name, properties));

    const handleGetAvailableValues = async (power: number) => {
        dispatch(toggleLoading());

        await apiCall(
            Api.getValues(power),
            async x => {
                dispatch(setAvailableValues(x.data));
            },
            async () => {
                dispatch(addSnackbar({
                    text: translate(TRANSLATIONS.error.availableValues), type: SnackbarType.Error
                }));
            }
        );

        dispatch(toggleLoading());
    };

    const handlePrimaryFilter = async (filter: PrimaryFilter) => {
        dispatch(toggleLoading());

        await apiCall(
            Api.primaryFilter(filter),
            async x => {
                dispatch(setAvailableValues(x.data.availableValues));
                dispatch(setFilter(x.data.filter));
                track('ShowResults', {
                    measurements: {
                        power: x.data.filter.primaryFilter.power,
                        speed: x.data.filter.primaryFilter.speed
                    }
                });
                x.data.filter.secondaryFilter && dispatch(setInitialSecondaryFilter(x.data.filter.secondaryFilter));
                dispatch(setMotors(x.data.motors));
                dispatch(clearHiddenIds());
                dispatch(clearNewIds());
                handleOptionValidation(getOptionValidationRequest(x.data.motors, ''), false);
            },
            async () => {
                dispatch(addSnackbar({
                    text: translate(TRANSLATIONS.error.filter), type: SnackbarType.Error
                }));
            }
        );

        dispatch(toggleLoading());
    };

    const handleSecondaryFilter = async (filter: Filter) => {
        dispatch(toggleLoading());

        await apiCall(
            Api.secondaryFilter(filter),
            async x => {
                dispatch(setAvailableValues(x.data.availableValues));
                dispatch(setFilter(getUpdatedFilters(filter, x.data.availableValues, x.data.motors.length === 0)));
                dispatch(setMotors(x.data.motors));
                dispatch(clearHiddenIds());
                dispatch(clearNewIds());
                handleOptionValidation(getOptionValidationRequest(x.data.motors, ''), false);
            },
            async () => {
                dispatch(addSnackbar({
                    text: translate(TRANSLATIONS.error.filter), type: SnackbarType.Error
                }));
            }
        );

        dispatch(toggleLoading());
    };

    const getUpdatedFilters = (filter: Filter, availableValues: AvailableValues, keepSelected: boolean) => (
        {
            primaryFilter: filter.primaryFilter,
            secondaryFilter: {
                productGroup: getUpdatedFilterValue(filter.secondaryFilter?.productGroup, availableValues.productGroup, keepSelected),
                winding: getUpdatedFilterValue(filter.secondaryFilter?.winding, availableValues.winding, keepSelected),
                material: getUpdatedFilterValue(filter.secondaryFilter?.material, availableValues.material, keepSelected),
                typeOfConstruction: getUpdatedFilterValue(filter.secondaryFilter?.typeOfConstruction, availableValues.typeOfConstruction, keepSelected),
                motorProtection: getUpdatedFilterValue(filter.secondaryFilter?.motorProtection, availableValues.motorProtection, keepSelected),
                terminalBox: getUpdatedFilterValue(filter.secondaryFilter?.terminalBox, availableValues.terminalBox, keepSelected),
                spFilter: getUpdatedFilterValue(filter.secondaryFilter?.spFilter, availableValues.spFilter, keepSelected)
            } as SecondaryFilter
        } as Filter
    );

    const getUpdatedFilterValue = (original: FilterValue | undefined, updated: FilterValue[], keepSelected: boolean) => (
        {
            value: original?.value,
            isAvailable: original?.value === null || updated.find(x => x.value === original?.value)?.isAvailable,
            isSelected: keepSelected && original?.isSelected
        } as FilterValue
    );

    const handleOptionValidation = async (request: OptionValidationRequest, useResultsLoader = true) => {
        useResultsLoader && dispatch(toggleLoading());
        dispatch(toggleLoadingOptions());
        dispatch(toggleLoadingDeliveryTimes());

        getCommercialData(getCommercialDataRequest(request));

        await apiCall(
            Api.optionValidation(request),
            async x => {
                dispatch(setOptionData(mergeOptionData(optionData, x.data)));
            },
            async () => {
                dispatch(addSnackbar({
                    text: translate(TRANSLATIONS.error.optionValidation), type: SnackbarType.Error
                }));
            }
        );

        useResultsLoader && dispatch(toggleLoading());
        dispatch(toggleLoadingOptions());
    };

    const getCommercialData = async (request: CommercialDataRequest) => {
        await apiCall(
            Api.getCommercialData(request),
            async x => {
                dispatch(setCommercialData(x.data));
            },
            async () => {
                dispatch(addSnackbar({
                    text: translate(TRANSLATIONS.error.commercialData), type: SnackbarType.Error
                }));
            }
        );

        dispatch(toggleLoadingDeliveryTimes());
    };

    return (
        <Selector onGetAvailableValues={handleGetAvailableValues} onOptionValidation={handleOptionValidation} onPrimaryFilter={handlePrimaryFilter} onSecondaryFilter={handleSecondaryFilter} />
    );
};

export default SelectorManager;
