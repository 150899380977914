import React from 'react';
import Slider from 'rc-slider';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import keyVisual from '../../../assets/images/background.jpg';
import { CAROUSEL_RESPONSIVE, INITIAL_POWER, OPTION_GROUP_NAME, TRANSLATIONS } from '../../constants';
import { IconType } from '../../enums';
import { useAppSelector, useResized, useTranslate } from '../../hooks/common';
import { Filter, FilterValue, Option, OptionData, OptionGroup, OptionValidationRequest, PrimaryFilter, SecondaryFilter } from '../../models';
import {
    addPinnedMotors,
    filterMotors,
    getFilterValue,
    getInitialFilter,
    getIsAvailable, getIsDisabled,
    getOptionValidationRequest,
    getSelectedPower,
    getTrackData,
    isOptionValid,
    setPowerFilter,
    setSecondaryFilter,
    setSelectedOption,
    setSpeedFilter,
    toggleOptionGroup,
    trackEvent
} from '../../services';
import {
    clearHiddenIds, setAvailableValues, setFilter, setInitialSecondaryFilter, setMotors, setOptionData, setPower, setPowerText,
    toggleBasicFilter,
    toggleSideSheet, toggleVerticalView
} from '../../store';
import Accordion from '../common/Accordion';
import Button, { ButtonType } from '../common/Button';
import Carousel from '../common/Carousel';
import Checkbox from '../common/Checkbox';
import ContentContainer from '../common/ContentContainer';
import Flex, { FlexDirection, FlexJustification } from '../common/Flex';
import Icon from '../common/Icon';
import Jumbotron from '../common/Jumbotron';
import KeyVisual from '../common/KeyVisual';
import Loader from '../common/Loader';
import Section, { SectionColor } from '../common/Section';
import Select from '../common/Select';
import SideSheet from '../common/SideSheet';
import SpacingContainer from '../common/SpacingContainer';
import TextInput from '../common/TextInput';
import Tooltip from '../common/Tooltip';
import Card, { CardType } from './Card';
import SelectorTagBar from './SelectorTagBar';


interface SelectorProps {
    onGetAvailableValues: (power: number) => void;
    onOptionValidation: (request: OptionValidationRequest) => void;
    onPrimaryFilter: (filter: PrimaryFilter) => void;
    onSecondaryFilter: (filter: Filter) => void;
}

const Selector = ({ onGetAvailableValues, onOptionValidation, onPrimaryFilter, onSecondaryFilter }: SelectorProps) => {
    const dispatch = useDispatch();
    const availableValues = useAppSelector(state => state.selector.availableValues);
    const basicFilterOpen = useAppSelector(state => state.selector.basicFilterOpen);
    const dtkData = useAppSelector(state => state.selector.dtkData);
    const filter = useAppSelector(state => state.selector.filter);
    const hiddenIds = useAppSelector(state => state.selector.hiddenIds);
    const initialAvailableValues = useAppSelector(state => state.selector.initialAvailableValues);
    const loading = useAppSelector(state => state.selector.loading);
    const loadingOptions = useAppSelector(state => state.selector.loadingOptions);
    const motors = useAppSelector(state => state.selector.motors);
    const optionData = useAppSelector(state => state.selector.optionData);
    const pinnedMotors = useAppSelector(state => state.selector.pinnedMotors);
    const powerText = useAppSelector(state => state.selector.powerText);
    const sideSheetOpen = useAppSelector(state => state.selector.sideSheetOpen);
    const verticalView = useAppSelector(state => state.selector.verticalView);
    const disableHorizontalView = useResized(x => x < 1200);
    const dtkKeepAliveIframe = useRef<HTMLIFrameElement>(null);
    const filteredMotors = filterMotors(motors, hiddenIds, optionData);
    const motorsToDisplay = addPinnedMotors(filteredMotors, pinnedMotors);
    const translate = useTranslate();
    type OptionObjectKey = keyof typeof TRANSLATIONS.option;
    type OptionGroupObjectKey = keyof typeof TRANSLATIONS.optionGroup;
    type MainObjectKey = keyof typeof TRANSLATIONS.main;

    useEffect(() => {
        dtkData && setInterval(() => {
            if (dtkKeepAliveIframe.current) {
                dtkKeepAliveIframe.current.src = dtkKeepAliveIframe.current.src;
            }
        }, 60000);
    }, [dtkData]);

    useEffect(() => {
        disableHorizontalView && !verticalView && dispatch(toggleVerticalView());
    }, [disableHorizontalView]);

    const track = (name: string, data: OptionData) => trackEvent(getTrackData(name, {}, data));

    const getSelectedPowerFromIndex = (value: number | number[]) => {
        const val = Array.isArray(value) ? value[0] : value;

        return availableValues?.power[val] ?? 0;
    };

    const handleSliderChange = (value: number | number[]) => {
        const selectedPower = getSelectedPowerFromIndex(value);

        dispatch(setPower(selectedPower));
        dispatch(setPowerText(selectedPower.toString()));
    };

    const handlePowerInputBlur = () => {
        if (powerText) {
            if (availableValues) {
                const selectedPower = getSelectedPower(availableValues, powerText);

                dispatch(setPower(selectedPower));
                handlePowerSubmit(selectedPower);
            }
        } else {
            dispatch(setPowerText(filter.primaryFilter.power.toString()));
        }
    };

    const handleSliderAfterChange = (value: number | number[]) => {
        const selectedPower = getSelectedPowerFromIndex(value);

        handlePowerSubmit(selectedPower);
    };

    const handlePowerSubmit = (selectedPower: number) => {
        filter.primaryFilter.speed
            ? onPrimaryFilter(setPowerFilter(filter, selectedPower).primaryFilter)
            : onGetAvailableValues(selectedPower);
    };

    const handleSpeedSelect = (speed: number) => {
        const newFilter = setSpeedFilter(filter, speed);

        dispatch(setFilter(newFilter));
        onPrimaryFilter(newFilter.primaryFilter);
    };

    const handleSecondarySelect = (secondaryFilter: Partial<SecondaryFilter>) => {
        const newFilter = setSecondaryFilter(filter, secondaryFilter);

        dispatch(setFilter(newFilter));
        onSecondaryFilter(newFilter);
    };

    const handleReset = () => {
        dispatch(setFilter(getInitialFilter()));
        dispatch(setPowerText(INITIAL_POWER.toString()));
        dispatch(setMotors([]));
        dispatch(setOptionData(undefined));
        dispatch(clearHiddenIds());
        dispatch(setInitialSecondaryFilter(undefined));
        initialAvailableValues && dispatch(setAvailableValues(initialAvailableValues));
    };

    const handleOptionGroupToggle = (index: number) => {
        optionData && dispatch(setOptionData(toggleOptionGroup(optionData, index)));
    };

    const handleOptionSelect = (name: string) => {
        if (optionData) {
            const newOptionData = setSelectedOption(optionData, name);

            dispatch(setOptionData(newOptionData));
            onOptionValidation(getOptionValidationRequest(motors, name, optionData));
            track('OptionSelection', newOptionData);
        }
    };

    const getOptionLabel = (option: Option) => {
        const key = option.name as OptionObjectKey;

        return `${option.name} ${translate(TRANSLATIONS.option[key])}`;
    };

    const isOptionDisabled = (option: Option) => !isOptionValid(option, filteredMotors);

    const isOptionSelected = (option: Option) => option.selected || filteredMotors.every(x => option.implicitMlfbs.includes(x.mlfb));

    const getOptionGroupIcon = (name: string) => {
        switch (name) {
            case OPTION_GROUP_NAME.BEARINGS:
                return IconType.Settings;
            case OPTION_GROUP_NAME.CERTIFICATES:
                return IconType.DocumentSuccess;
            case OPTION_GROUP_NAME.DOCUMENTATION:
                return IconType.Document;
            case OPTION_GROUP_NAME.MECHANICAL_VERSION:
                return IconType.Configure;
            case OPTION_GROUP_NAME.PAINTING:
                return IconType.Plant;
            default:
                return IconType.Settings;
        }
    };

    const getSelectedFilterValue = (filterValue: FilterValue) => {
        const selectedFilterValue = { ...filterValue, isSelected: true } as FilterValue;

        if (filterValue === null) {
            selectedFilterValue.isAvailable = true;
        }

        return selectedFilterValue;
    };

    const renderPowerInput = () => (
        <div className='power-slider'>
            <Slider min={0} max={availableValues ? availableValues?.power.length - 1 : 0} value={availableValues?.power.indexOf(filter.primaryFilter.power) ?? 0}
                disabled={loading} onChange={handleSliderChange} onAfterChange={handleSliderAfterChange} />
            <TextInput name='power' label={`${translate(TRANSLATIONS.main.power)} [kW]`} value={powerText} disabled={loading} onBlur={handlePowerInputBlur}
                onChange={x => !isNaN(Number(x)) && dispatch(setPowerText(x))} cypressAttribute='power-input' />
        </div>
    );

    const renderSpeedInput = () => (
        <Select label={`${translate(TRANSLATIONS.main.synchronousSpeed)} [${translate(TRANSLATIONS.main.rpm)}]`} value={filter.primaryFilter.speed ?? 0} values={availableValues?.speed}
            disabled={loading} mapToString={x => x.toString()} onSelect={handleSpeedSelect} cypressAttribute='speed-input' required />
    );

    const getResultsLabel = () => {
        const key = motors.length ? 'noResults' : 'pleaseEnterMotorData' as MainObjectKey;

        return translate(TRANSLATIONS.main[key]);
    };

    const getOptionGroupName = (optionGroup: OptionGroup) => {
        const key = optionGroup.name as OptionGroupObjectKey;

        return translate(TRANSLATIONS.optionGroup[key]);
    };

    return (
        <div className='selector'>
            <KeyVisual height={360} url={keyVisual}>
                <Flex direction={FlexDirection.Column} justification={FlexJustification.Center}>
                    <ContentContainer>
                        <Jumbotron>
                            <div className='primary-filters'>
                                <div className='title-and-speed-delivery'>
                                    <div className='title'><>{translate(TRANSLATIONS.main.motorFeatures)}</></div>
                                </div>
                                {renderPowerInput()}
                                <div className='speed-and-all-filters'>
                                    {renderSpeedInput()}
                                    <Button type={ButtonType.Secondary} onClick={() => dispatch(toggleSideSheet())}>
                                        <>
                                            <Icon type={IconType.Filter} />
                                            {translate(TRANSLATIONS.main.allFilters)}
                                        </>
                                    </Button>
                                </div>
                            </div>
                        </Jumbotron>
                    </ContentContainer>
                </Flex>
            </KeyVisual>
            <SideSheet open={sideSheetOpen} title={translate(TRANSLATIONS.main.allFilters)} actions={[{ key: 'reset', children: <Icon type={IconType.Reset} />, onClick: handleReset }]}
                onClose={() => dispatch(toggleSideSheet())}>
                <Accordion open={basicFilterOpen} label={translate(TRANSLATIONS.main.basicFilter)} icon={IconType.Filter} onClick={() => dispatch(toggleBasicFilter())}>
                    <SpacingContainer>
                        {renderPowerInput()}
                        {renderSpeedInput()}
                        {Boolean(filter.primaryFilter.power && filter.primaryFilter.speed) &&
                            <>
                                <Select label={translate(TRANSLATIONS.main.productGroup)}
                                    value={getFilterValue(filter.secondaryFilter?.productGroup)}
                                    values={availableValues.productGroup}
                                    disabled={loading}
                                    mapToString={x => x.value}
                                    onSelect={x => handleSecondarySelect({ productGroup: getSelectedFilterValue(x) })}
                                    isAvailable={getIsAvailable}
                                    isDisabled={x => getIsDisabled(x, filter)}
                                    cypressAttribute='product-group' />
                                <Select label={translate(TRANSLATIONS.main.winding)}
                                    value={getFilterValue(filter.secondaryFilter?.winding)}
                                    values={availableValues.winding}
                                    disabled={loading}
                                    mapToString={x => x.value}
                                    onSelect={x => handleSecondarySelect({ winding: getSelectedFilterValue(x) })}
                                    isAvailable={getIsAvailable}
                                    isDisabled={x => getIsDisabled(x, filter)}
                                    cypressAttribute='winding' />
                                <Select label={translate(TRANSLATIONS.main.line)}
                                    value={getFilterValue(filter.secondaryFilter?.material)}
                                    values={availableValues.material}
                                    disabled={loading}
                                    mapToString={x => x.value}
                                    onSelect={x => handleSecondarySelect({ material: getSelectedFilterValue(x) })}
                                    isAvailable={getIsAvailable}
                                    isDisabled={x => getIsDisabled(x, filter)}
                                    cypressAttribute='line' />
                                <Select label={translate(TRANSLATIONS.main.typeOfConstruction)}
                                    value={getFilterValue(filter.secondaryFilter?.typeOfConstruction)}
                                    values={availableValues.typeOfConstruction}
                                    disabled={loading}
                                    mapToString={x => x.value}
                                    onSelect={x => handleSecondarySelect({ typeOfConstruction: getSelectedFilterValue(x) })}
                                    isAvailable={getIsAvailable}
                                    isDisabled={x => getIsDisabled(x, filter)}
                                    cypressAttribute='construction-type' />
                                <Select label={translate(TRANSLATIONS.main.motorProtection)}
                                    value={getFilterValue(filter.secondaryFilter?.motorProtection)}
                                    values={availableValues.motorProtection}
                                    disabled={loading}
                                    mapToString={x => x.value}
                                    onSelect={x => handleSecondarySelect({ motorProtection: getSelectedFilterValue(x) })}
                                    isAvailable={getIsAvailable}
                                    isDisabled={x => getIsDisabled(x, filter)}
                                    cypressAttribute='motor-protection' />
                                <Select label={translate(TRANSLATIONS.main.terminalBoxPosition)}
                                    value={getFilterValue(filter.secondaryFilter?.terminalBox)}
                                    values={availableValues.terminalBox}
                                    disabled={loading}
                                    mapToString={x => x.value}
                                    onSelect={x => handleSecondarySelect({ terminalBox: getSelectedFilterValue(x) })}
                                    isAvailable={getIsAvailable}
                                    isDisabled={x => getIsDisabled(x, filter)}
                                    cypressAttribute='terminal-box' />
                                <Select label={translate(TRANSLATIONS.main.spFilter)}
                                    value={getFilterValue(filter.secondaryFilter?.spFilter)}
                                    values={availableValues.spFilter}
                                    disabled={loading}
                                    mapToString={x => x.value}
                                    onSelect={x => handleSecondarySelect({ spFilter: getSelectedFilterValue(x) })}
                                    isAvailable={getIsAvailable}
                                    isDisabled={x => getIsDisabled(x, filter)}
                                    cypressAttribute='sp-filter' />
                            </>
                        }
                    </SpacingContainer>
                </Accordion>
                <Loader loading={loading || loadingOptions} marginTop={optionData ? 0 : -30}>
                    {optionData && optionData.optionGroups.map((x, i) =>
                        <Accordion key={x.name} open={x.open} label={getOptionGroupName(x)} icon={getOptionGroupIcon(x.name)} onClick={() => handleOptionGroupToggle(i)}>
                            <SpacingContainer>
                                {x.options.map(y =>
                                    <Tooltip key={y.name} id={y.name} text={getOptionLabel(y)} place='left'>
                                        <Checkbox label={getOptionLabel(y)} checked={isOptionSelected(y)} disabled={isOptionDisabled(y)} onChange={() => handleOptionSelect(y.name)} />
                                    </Tooltip>
                                )}
                            </SpacingContainer>
                        </Accordion>
                    )}
                </Loader>
            </SideSheet>
            <Section color={SectionColor.LightSand} style={{ paddingTop: 15 }}>
                <ContentContainer>
                    <SelectorTagBar backgroundColor={SectionColor.LightSand} onOptionValidation={onOptionValidation} onSecondaryFilter={onSecondaryFilter} />
                    <div className='results'>
                        <Loader loading={loading} margin={5} marginBottom={motorsToDisplay.length && verticalView ? 35 : 5} marginLeft={30} marginRight={30} transparent={!motorsToDisplay.length}>
                            {motorsToDisplay.length
                                ? verticalView
                                    ? <Carousel slidesToShow={4} responsive={CAROUSEL_RESPONSIVE}>
                                        {motorsToDisplay.map(x =>
                                            <div key={x.id}>
                                                <Flex direction={FlexDirection.Row} justification={FlexJustification.Center}>
                                                    <Card key={x.id} motor={x} type={CardType.Vertical} />
                                                </Flex>
                                            </div>
                                        )}
                                    </Carousel>
                                    : <Flex direction={FlexDirection.Column} justification={FlexJustification.Center} gap={10}>
                                        {motorsToDisplay.map(x =>
                                            <Card key={x.id} motor={x} type={CardType.Horizontal} />
                                        )}
                                    </Flex>
                                : <div className='results-placeholder'>
                                    {loading ? <br /> : getResultsLabel()}
                                </div>
                            }
                        </Loader>
                    </div>
                </ContentContainer>
            </Section>
            {dtkData &&
                <iframe ref={dtkKeepAliveIframe} className='dtkKeepAliveIframe' src={dtkData.keepAliveUrl} />
            }
        </div>
    );
};

export default Selector;
